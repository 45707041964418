:root {
    --darkClr: #1e293b;
    --greyClr: #64748b;
    --blueClr: #545081;
    --blueClrHover: #5c5e91;
    --whiteClr: #fff;
    --lightBgClr: #f1f5f9;
    --redClr: #ef4444;
    --yellowClr: #f59e0b;
    --greenClr: #22c55e;
    --darkBlue: #3730a3;
    --darkGreen: #166534
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('../fonts/Poppins-Light.ttf');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url("../fonts/Poppins-SemiBold.ttf");
}

body {
    font-family: 'Poppins-Regular';
}

.WrapLeft {
    width: calc(100% - 65px);
    float: left;
    border-right: 1px solid #cccccc82
}

.WrapRight {
    width: 65px;
    float: left;
    box-shadow: 0 1px 2px #cccccc82;
    padding: 25px 5px;
    text-align: center;
}

.WrapRight svg {
    font-size: 30px;
    color: #64748b;
    cursor: pointer;
    padding: 2px;
    margin: 20px 4px;
}

/* ======================================================================== */
.close-sidebar {
    position: absolute;
    /* right: 0; */
    width: 65px;
    /* height: 35px; */
    display: block;
}

.close-sidebar span {
    width: 40px;
    display: block;
    height: 2px;
    margin-top: 5px;
    margin: 4px auto;
    background-color: #64748b;
    position: relative;
    transition: 0.3s all ease-in-out;
}

.open span {
    width: 20px;
}

.open span:nth-child(1) {
    transform-origin: right;
    transform: rotate(-36deg);
}

.open span:nth-child(2) {
    width: 0;
}

.open span:nth-child(3) {
    transform-origin: right;
    transform: rotate(36deg);
}

.sidebar a {
    color: #fff;
    text-decoration: none;
    font-size: 25px;
}

.sidebar {
    width: 385px;
    height: 100%;
    position: fixed;
    background-color: #fff;
    color: #fff;
    right: 0px;
    top: 0;
    /* overflow: hidden; */
    transition: 0.5s all ease-in-out;
    padding: 5px 0px;
    border-left: 1px solid #ddd;


}

.close-sidebar.open .svgComments,
.sidebar.closed .close-sidebar span {
    display: none;
}

.sidebar.closed h3 {
    visibility: hidden;
}

.close-sidebar.open span {
    cursor: pointer;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin-top: 0px;
    width: 65px;
    float: left;
    border-right: 1px solid #ddd;

}

.sidebar ul:hover {
 visibility: visible;
}

#style-1::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
}

#style-1::-webkit-scrollbar-thumb {
    background-color: #ccc;
}

.sidebar ul li {
    margin-top: -1px;
    /* padding-left: 18px; */
}

.sidebar ul li:hover {
    background: rgb(238, 242, 255)
}

.sidebar ul li a {
    position: relative;
    transition: 0.3s all ease-in-out;
    padding-right: 10px;
    display: block;
    text-decoration: none;
    text-align: left;
    line-height: 45px;
    font-size: 20px;
    color: #fff;
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.userImgsRight {
    width: 30px;
    height: 30px;
    border-radius: 50%;    object-fit: cover;object-position: top;
}

.userImgsRightHead {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
}

.WrapRight h3 {
    color: #000;
    font-size: 15px;
    font-family: 'Poppins-Medium';
    text-align: left;
    margin: 0;    width: 100%;
    padding: 30px 15px;
    border-bottom: 1px solid #ddd;    display: flex;
    align-items: center;
}

.close-sidebar.open {
    position: absolute;
    right: inherit;
    margin-right: 0px;
    top: 28px;
    cursor: pointer;
}

.closed {
    right: -320px;
}

.closed ul li a:after {
    width: 45px;
    right: -4px;
}

/* ========================================================================= */

.header {
    background-color: #fff;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #cccccc82;
    box-shadow: 0 1px 2px #cccccc82;
    align-items: center;
}

.logo {
    display: flex;
}

.headerMenus ul li,
.headerRight ul li {
    list-style: none;
    display: inline-flex;
    padding: 0 8px;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
}

.headerMenus ul li a,
.headerRight ul li a {
    color: #212529;
    text-decoration: none;
}

.headerMenus ul,
.headerRight ul {
    margin: 0px;
}

.headerMenus ul li svg {
    margin-right: 5px;
    color: #64748b;
    font-size: 18px;
}

.headerRight ul li svg {
    color: #64748b;
    font-size: 18px;
}

.headerRight {
    display: inline-flex;
    align-items: center;
}

.userImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid #fff;
}

.userImg:hover {
    border: 5px solid #ccccccad;
}

.userInfo {
    padding-left: 15px;
    cursor: pointer;
}

.dashboardContent {
    padding: 50px 30px;
}

.userImgs {
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.dashWelcTxt {
    display: flex;
    justify-content: left;
    align-items: center;
}

.dashWelcTxt h2 {
    color: var(--darkClr);
    font-size: 35px;
    margin: 0;
    font-family: 'Poppins-SemiBold';
}

.uImg {
    margin-right: 15px;
}

.dashWelcTxt p {
    color: var(--greyClr);
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: left;
}

.dashWelcTxt svg {
    margin-right: 8px;
}

.dashClmRight {
    display: flex;
    justify-content: end;
    align-items: center;
}

.dashAction button,
.dashAction a {
    font-size: 18px;
    border: none;
    border-radius: 6px;
    padding: 7px 20px;
    display: inline-flex;
    align-items: center;
    margin-left: 15px;
    color: var(--whiteClr);
    text-decoration: none;
    cursor: pointer;
    min-width: 120px;
    justify-content: center;
    color: #000;
}

.dashAction button svg,
.dashAction a svg {
    margin-right: 8px;
}

.btnNavy {
    /* background-color: var(--darkClr); */
    background-color: #a7c5eb;
}
.btnNavy:hover{
    /* background-color: #1e293bdb; */
    background-color: #bbd2ef;
}

.btnBlue {
    background-color: var(--blueClr);
}

.btnRed {
    background-color: var(--redClr);
}
.btnCancel{
    background-color: #c3a6a6; 
 }
 .btnCancel:hover {
    background-color: #565f6ccc;
}
.btnAdd{
    background-color: #b9c4b6 ; 
 }
 .btnAdd:hover {
    background-color: #8dcb80;
}
.tabContent {
    /* background-color: var(--lightBgClr); */
    padding: 25px 30px;
    border: 1px solid #ddd;
    /* background-color: #bbd2ef7a; */
    background-color: #f3f4f6;
}

.tabContent .react-tabs__tab {
    border: none;
}

/* .tabContent .react-tabs__tab:hover {
    background: #e7edf3;
} */

.tabContent .react-tabs__tab {
    border-bottom: 2px solid transparent;
    font-size: 18px;
    font-weight: 500;
    padding: 13px 23px;

}

.tabContent .react-tabs__tab-list {
    border-bottom: 1px solid #ddd;
}

.tabContent .react-tabs__tab--selected {
    background: transparent;
    border-color: var(--blueClr);
    color: var(--blueClr);
    border-left: none;
    border-right: none;
    border-top: none;
}

.tabContent .react-tabs__tab:focus:after {
    visibility: hidden;
}

.dashCards {
    background: var(--whiteClr);
    padding: 20px 20px;
    border-radius: 15px;
    box-shadow: 0 3px 6px #ccc;
    margin: 8px 0;
}

.dashCards h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 17px;
    font-family: 'Poppins-Medium';
}

.dashCards span svg {
    color: #5e5e5e;
    font-size: 14px;
}

.dashCount {
    text-align: center;
    padding: 5px 0px 0;
}

.dashCount h1 {
    font-size: 55px;
    /* font-size: 36px; */
    margin: 0;
    color: var(--blueClr);
    font-family: 'Poppins-Bold';
}

.dashCount p {
    font-size: 13px;
    color: var(--blueClr);
    font-family: 'Poppins-Medium';
    margin-bottom: 8px;
}

.dashCount.redClr h1,
.dashCount.redClr p {
    color: var(--redClr);
}

.dashCount.yellowClr h1,
.dashCount.yellowClr p {
    color: var(--yellowClr);
}

.dashCount.greenClr h1,
.dashCount.greenClr p {
    color: var(--greenClr);
}

.dashCount.darkBlue h1,
.dashCount.darkBlue p {
    color: var(--darkBlue);
}

.dashCount.darkGreen h1,
.dashCount.darkGreen p {
    color: var(--darkGreen);
}

.dashCount h6 {
    margin: 0;
    color: var(--greyClr);
    font-size: 15px;
    font-family: 'Poppins-Medium';
}

.dashCount h6 span {
    font-size: 16px;
    font-family: 'Poppins-Bold';
}

.br50 {
    border-radius: 50%;
}

.dashCount.dashTeams p {
    color: var(--darkClr);
    font-size: 15px;
    margin: 0;
}

.dashCount.dashTeams h6 {
    color: var(--greyClr);
    font-size: 14px;
}

.teamsInfo {
    padding: 25px 5px
}

.teamConn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ddd;
}

.teamConn h2 {
    margin: 0px;
    font-size: 14px;
    padding: 15px 0;
    width: 50%;
    justify-content: center;
}

.teamConn h2 svg {
    color: var(--greyClr);
    margin-right: 8px;
    font-size: 18px;
}

.dashCards.dashTeamMemb {
    padding: 0;
}

.dashCards.dashTeamMemb .dashCount {
    padding: 30px 0px 0px;
}

.teamConn h2:first-child {
    border-right: 1px solid #ddd;
}

.memberCount {
    padding: 3px 7px;
    background: #d4e1ed;
    border-radius: 50%;
    margin-left: 6px;
    color: #000;
    font-size: 12px;
}

.issueSummWrap {
    background: var(--whiteClr);
    padding: 20px 20px;
    border-radius: 15px;
    box-shadow: 0 3px 6px #ccc;
    margin: 15px 0;
}

.issueSummWrap h2 {
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'Poppins-Medium';
}

.summaryTabs h2 {
    font-size: 14px;
    color: #878585;
}

.issueSummWrap {
    position: relative;
    height: 500px;
}

.issueSummWrap .react-tabs__tab-list {
    position: absolute;
    right: 15px;
    top: 20px;
}

.tabContent .issueSummWrap .react-tabs__tab {
    border-bottom: 0px solid transparent;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 25px;
    border-radius: 20px;
    margin: 0 10px;
}

.tabContent .issueSummWrap .react-tabs__tab--selected {
    background: transparent;
    border-color: none;
    color: #000;
    border-left: none;
    border-right: none;
    border-top: none;
    background: #a7c5eb; 
}
.tabBooksList .react-tabs__tab:nth-child(1) {
    background: #167623c2;border: 2px solid #fff; color:#fff
}
.tabBooksList .react-tabs__tab:nth-child(2){
    background: #df7878;border: 2px solid #fff; color:#fff
}
.tabBooksList .react-tabs__tab:nth-child(3){
    background: rgb(131 72 241);border: 2px solid #fff; color:#fff
}
.tabBooksList  .react-tabs__tab--selected:nth-child(1) { 
    background: #fff; 
     border: 2px solid #167623c2;color: #167623c2;
}
.tabBooksList   .react-tabs__tab--selected:nth-child(2) { 
    color: #df7878; 
    background: #fff; 
    border: 2px solid #df7878;
}
.tabBooksList   .react-tabs__tab--selected:nth-child(3) { 
    color: rgb(131 72 241);
    background: #fff; 
    border: 2px solid rgb(131 72 241);
}

.tabContent .issueSummWrap .react-tabs__tab-list {
    border: none;
}

.issueBtmTxt h1 {
    font-size: 35px;
    color: var(--greyClr);
}

.issueBtmTxt p {
    font-size: 12px;
    color: var(--greyClr);
}

.issueBtmTxt .dashCards,
.issueTopTxt .dashCards {
    padding: 12px 7px;
    box-shadow: none;
}

.scheduleList ul {
    padding: 0px;
}

.scheduleList ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.scheduleList ul li h5 {
    margin: 0px;
}

.scheduleList ul li h3 {
    font-size: 13px;
    margin: 0;
    margin-bottom: 5px;
}

.scheduleList ul li p {
    font-size: 13px;
    display: flex;
    align-items: center;
    margin: 0;
    color: #94a3b8;
}

.scheduleList ul li p svg {
    font-size: 16px;

}

.scheduleList ul li p svg,
.scheduleList ul li p span {
    margin-right: 8px;
}

.scheduleList h1 svg {
    font-size: 20px;
    color: #979797;
}

.scheduleList {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 7px;
}

.teamConn1 span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    padding: 17px;

}

.teamConn1 span:first-child {
    border-right: 1px solid #ddd;
}

.teamConn1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ddd;
}

.teamConn1 span h2 {
    margin: 0px;
    font-size: 40px;
    color: #000;
}

.teamConn1 span p {
    margin: 0;
    font-size: 14px;
    color: #878585;
    font-weight: 500;
}

.lineChartTxt h4 {
    margin: 0;
    font-size: 22px;
}

.lineChartTxt p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
}

.lineChartTxt {
    display: flex;
    align-items: center;
}

.greenTxt {
    color: var(--greenClr);
}

.issueSummWrap.heightAuto {
    height: auto;
}

.issueSummWrap.heightAuto h2 {
    margin: 0;
}

/*  */
.budgetTable table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
}

.budgetTable th {
    color: #000;
    font-weight: 500;
    font-family: 'Poppins-Medium';
}

.budgetTable td,
.budgetTable th {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 17px;

}

.budgetTable td {
    padding: 5px 10px;
}

.budgetTable tr:last-child {
    border: none;
}

/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

    .budgetTable table {
        width: 100%;
    }

    /* Force table to not be like tables anymore */
    .budgetTable table,
    .budgetTable thead,
    .budgetTable tbody,
    .budgetTable th,
    .budgetTable td,
    .budgetTable tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .budgetTable thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    /* .budgetTable tr { border: 1px solid #ccc; } */

    .budgetTable td {
        border: none;
        border-bottom: 1px solid #ddd;
        position: relative;
        padding-left: 50%;
    }

    .budgetTable td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        content: attr(data-column);

        color: #000;
        font-weight: bold;
    }

}

.dots {
    width: 6px;
    height: 6px;
    display: inline-flex;
    margin-right: 10px;
    position: relative;
    top: -2px;
    border-radius: 50%;
}

.blueDot {
    background: blue;
}

.RedDot {
    background: red;
}

.GreenDot {
    background: green;
}

.YellowDot {
    background: yellow;
}

/*  */

.separator {
    display: flex;
    align-items: center;
    background: #f2f2f2;
    padding: 5px 0;
}

.separator .line {
    height: 1px;
    flex: 1;
    background-color: #ddd;
}

.separator h2 {
    padding: 0 15px;
    font-size: 12px;
    color: #64748b;
}

/* ====================== */

.app-message-body {
    background-color: rgba(233, 233, 233, 0.6);
    padding: 24px 16px 0;
    font-size: 14px;
    overflow-y: auto;
    max-height: 75vh;
}

.app-message-body .message-wrapper {
    display: flex;
    flex-direction: column;
}

.app-message-body .message-wrapper:nth-child(2n+1) {
    align-items: flex-start;
}

.app-message-body .message-wrapper:nth-child(2n+1) .message-meta {
    justify-content: flex-start;
}

.app-message-body .message-wrapper:nth-child(2n) {
    align-items: flex-end;
}

.app-message-body .message-wrapper:nth-child(2n) .message-meta {
    flex-direction: row-reverse;
}

.message {
    max-width: 66.6%;
    background-color: #fff;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 4px 4px rgba(200, 200, 200, 0.25);
    font-size: 12px;
    display: flex;
    flex: 1 1 auto;
    color: #000;
    text-align: left;
}

.message-meta {
    display: flex;
    flex: 1 auto;
    margin: 10px 5px 15px;
}

.message-meta .sender-avatar {
    width: 24px;
    height: 24px;
    border-radius: 9999px;
    background: linear-gradient(180deg, #1e293bc2, #232f41cf);
    color: #fff;
    line-height: 24px;
    text-align: center;
    font-size: 0.666rem;
    font-weight: 600;
}

.message-meta .timestamp {
    color: #999;
    font-size: 0.666rem;
    line-height: 24px;
    display: inline-block;
    margin: 0 5px;
}

.app-input-area {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 3px 6px #ccc;
}

.app-input-area input[type=text] {
    flex: 1 80%;
    border: none;
    padding: 0.33rem;
    margin: auto 10px;
    font-size: 13px;
    outline: none;
}

.app-input-area button[type=submit] {
    flex: 1 5%;
    border: none;
    margin: 0 auto;
    /*background: linear-gradient(45deg, #fb551c, #f7b633);*/
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.app-input-area img {
    width: 20px;
}

.online {
    width: 10px;
    height: 10px;
    display: block;
    /* background: green; */
    border-radius: 50%;
    position: relative;
    top: -7px;
    right: -35px;
    /* border: 1px solid #fff; */
    border: 1px solid transparent;
    background: transparent;
}

.userInfo span.online {
    width: 10px;
    height: 10px;
    display: block;
    background: green;
    border-radius: 50%;
    position: relative;
    top: -15px;
    right: -27px;
    border: 1px solid #fff;
}

#target {
    background: #fff;
    border: 1px solid #ddd;
    width: 200px;
    height: auto;

    display: none;
    position: absolute;
    right: 105px;
    border-radius: 6px;
}

#target ul {
    padding: 0;
    margin: 0;
}

#target ul li {
    list-style: none;
    font-size: 12px;
    padding: 5px 8px;
    cursor: pointer;
    width: 100%;
}

#target ul li:hover {
    background: #f2f2f2;
}

.toggle {
    border: none;
    background-color: transparent;
}

.spanUser svg {
    margin-right: 8px;
    color: #64748b;
}

.mainHead h2 {
    font-size: 26px;
    color: var(--darkClr);
    font-family: 'Poppins-Bold';
}

.dashboardContentInner {
    /* padding: 30px 30px 10px; */
    padding: 20px 15px 0px;
}

.tabContentsWrap {
    padding: 10px;
}

.tabContentsWrap .issueSummWrap {
    box-shadow: none;
    margin: 0;
    padding-top: 0;
    padding: 0;
}

.charName {
    width: 30px;
    height: 30px;
    display: inline-flex;
    background: #ddd;
    border-radius: 50%;
    font-size: 13px;
    font-family: 'Poppins-Medium';
    padding: 5px;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    font-weight: 600;
}

.tabContentsWrap .budgetTable th {
    background: #bbd2ef;
    /* background: #ddd; */
}

.tabContentsWrap .budgetTable table {
    width: 100%;
    border-collapse: collapse;
    margin: 15px auto;
    border: 1px solid #dddd;
}

.tabContentsWrap .budgetTable td {
    padding: 10px 10px;
}

.tabContentsWrap .issueSummWrap .react-tabs__tab-list {
    position: sticky;
    background: #fff;
    right: 15px;
    top: 0px;
}

.tabContentsWrap .issueSummWrap .react-tabs__tab-list {
    border-bottom: 0px solid #ddd;
}

.tabContentsWrap .react-tabs__tab {
    padding: 6px 0px 0;
    margin-right: 20px;
    font-size: 13px;
    font-family: 'Poppins-Medium';
    border-bottom: 2px solid transparent;
}

.tabContentsWrap .react-tabs__tab--selected {
    background: #fff;
    border-color: transparent;
    color: var(--blueClr);
    border-radius: 0;
    border-bottom: 2px solid var(--blueClr);
}

.tabContentsWrap .dashAction button svg {
    margin-right: 0px;
}

.tabContentsWrap .dashAction button {
    font-size: 14px;
    border: none;
    border-radius: 10px;
    padding: 8px 15px;
    display: inline-flex;
    align-items: center;
    margin-left: 15px;
    color: var(--whiteClr);
}

/* SEARCH BAR CONTAINER */
.search-local {
    width: 100%;
    /* max-width: 75rem; */
    display: flex;
    align-items: center;
    /* column-gap: 0rem; */
    padding-left: 10px;
    background: #fff;
    border-radius: 4px;
    min-height: 38px;
    height: auto;
    border: 0.1rem solid hsl(0, 0%, 80%);
    border-right: none;
}

/* ICON */
.icon {
    color: var(--darkClr);
    font-size: 13px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.icon:hover {
    animation: funny-icon 0.4s ease-in-out;
}

@keyframes funny-icon {
    0% {
        scale: 1;
    }

    50% {
        scale: 0.8;
        transform-origin: bottom;
        transform: rotate(-15deg);
    }

    100% {
        scale: 1;
    }
}

/* INPUT */
.search-local input {
    height: 100%;
    width: 100%;
    flex: 1 1 25rem;
    background: #fff;
    display: flex;
    outline: none;
    border: none;
    color: #000;
    font-size: 13px;
}


.search-local input[type="text"]::placeholder, #selectFilter::placeholder,#react-select-2-placeholder{
    color: #3f3f3f;
    font-size: 13px;
}

/* BUTTON */
.search-local button {
    background: #1e293bbf;
    border: 1px solid #565f6c;
    border-radius: 0 4px 4px 0;
    font-weight: 500;
    font-size: 13px;
    height: 38px;
    width: 200px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    text-decoration: none;color: #fff;
}

.search-local button a {
    color: #fff;
    text-decoration: none;
}

.search-local button .search-icon {
    display: none;
}

.search-local button:hover {
    letter-spacing: 1px;
}

/* MEDIAS */
@media (max-width: 575px) {
    .search-local {
        padding-left: 0;
        column-gap: 0.25rem;
    }

    .search-local button {
        width: 10rem;
    }

    .search-local button a {
        display: none;
    }

    .search-local button .search-icon {
        display: block;
        margin-inline: auto;
        color: #fff;
        font-size: 14px;
    }

    .search-local input[type="text"]::placeholder {
        font-size: 14px;
    }
}

.fiterSection .form-control {
    height: 30px;
    font-size: 13px;
    border: 1px solid #1e293b8c;
}

.justfyEnd {
    justify-content: end;
}

.fiterSection .form-control svg {
    position: relative;
    top: -2px;
    left: -2px;
    font-size: 17px;
    color: #1e293bbf;
}

.fiterSection {
    margin: 25px 0;
}

select.classic {
    background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, #1e293bbf, #1e293bbf);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        100% 0;
    background-size: 5px 6px, 5px 5px, 40px 30px;
    background-repeat: no-repeat;
    cursor: pointer;
}

select.classic:focus {
    background-image:
        linear-gradient(45deg, white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, white 50%),
        linear-gradient(to right, gray, gray);
    background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        100% 0;
    background-size: 5px 6px, 5px 5px, 40px 30px;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
}

.dcenter {
    display: flex;
    justify-content: end;
    align-items: center;
}

.iconsTxt {
    width: 50px;
    height: 50px;
    background: #e88073;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid #a3a1a3;
    padding: 5px;
    color: #fff;
    position: relative;
    top: 10px;
}

.iconCont h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.iconCont p {
    margin: 0;
    font-size: 13px;
}

.iconContBg {
    display: flex;
    align-items: center;
    color: #fff;

}

.iconCont {
    background: #e88073;
    padding: 20px 24px;
    position: relative;
    right: 7px;
    min-height: 102px;
    top: 10px;
}

.greyBg .iconCont,
.greyBg .iconsTxt {
    background: #8e8d89;
}

.brownBg .iconCont .brownBg .iconsTxt,
.brownBg .iconsTxt,
.brownBg .iconCont,
.brownBg .summaryTabs1.budgetTable .tableList th {
    background: #cbab7c;
}

.greyBg .item1 {
    border-bottom: 23px solid #757470
}

.brownBg .item1 {
    border-bottom: 23px solid #b9955d;
}

.tableList {
    margin: 0px !important;
}

.summaryTabs1.budgetTable .tableList th {
    background: #e88073;
    color: #fff;
}

.greyBg .summaryTabs1.budgetTable .tableList th {
    background: #8e8d89;
}

.paddingleft {
    padding-left: 0px !important;
}

.item1 {
    width: 147px;
    height: 0px;
    margin: 0px auto 0 auto;
    border-bottom: 23px solid #e95950;
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    transform: rotate(270deg);
    position: relative;
    top: 85px;
    left: -265px;

}

.greyClr {
    border-bottom: 23px solid #8e8d89;
}

.tabContentsWrap .budgetTable .tableList td {
    padding: 6.5px 10px;
}

.dflexcenter {
    display: flex;
    justify-content: center;
}

.dflexcenter span {
    margin: 7px 10px;
}

.dflexcenter .form-control,.loginFields .form-control{
    margin-bottom: 20px;
    font-size: 13px;
    border: 1px solid #1e293b38;
    height: 40px;
}

.dflexcenter .form-control::placeholder {
    font-size: 12px;
}

.dflexcenter span svg {
    font-size: 20px;
    color: #64748b;
}

.fromWrap {
    margin: 0 150px;
}

.txtcenter {
    text-align: center;
}
.dcenterLogin {
    display: flex;
    justify-content: left;
    align-items: center;
}
.loginSection h2{
    font-size: 28px;
    font-family: 'Poppins-Bold';
}
.loginSection img{
    margin-bottom: 25px;width: 70%;
}
.loginSection h4{
    font-size: 14px;
    font-family: 'Poppins-Medium';
    margin-bottom: 25px;
}.loginWrap .loginSection h4 a{
    color: var(--blueClr) ;cursor: pointer;
}
.loginFields label,.loginFields span{
    font-size: 13px;
}
.loginSection{
    width: 100%;    padding: 30px;
}
.loginFields{
    width: 90%;
}


.rememberme.form-group {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
  }
  
  .rememberme.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .rememberme.form-group label {
    position: relative;
    cursor: pointer;
  }
  .rememberme.form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid var(--darkBlue);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .rememberme.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 5px;
    height: 11px;
    border: solid var(--darkBlue);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .loginBtn {
    background: var(--blueClr);
    width: 100%;
    color: #fff;
    padding: 8px;
    text-align: center;
    border-radius: 20px;
    margin-top: 25px;
    display: block;
    text-decoration: none;border: none;
  }
  .loginBtn:hover{
    background: var(--blueClrHover);
  }
  .loginImgposition{
    position: relative;
  }
  .loginBgTxts{
    position: absolute;
    top: 40%;
    color: #fff;
    width: 80%;
    text-align: left;
    padding-left: 15%;
  }
 .loginBgTxts h2{
    font-family: 'Poppins-Bold';
    font-size: 39px;margin-bottom: 25px;
    color: #fff;text-shadow: 0 0 #fff;
  }
  .loginBgTxts p{
    font-size: 14px;
    font-family: 'Poppins-Light';
    /* color: #ffffff9e; */
    color: #fff;text-shadow: 0 0 #fff;
  }
  .errorMsg{
    font-size: 12px;
    color: red;
    margin: 0;
  }
/* ================ TOOLTIP CSS BEGINS HERE ================ */
/*     */
 
.sidebar-navigation {
    display: inline-block;
    min-height: 100vh;
    /* width: 80px; */
    /* margin-top: 80px; */
    float: left;    margin-top: 20px;
  }
  .sidebar-navigation ul {
    text-align: center;
    color: white;    max-height: 90vh;
    overflow-y: auto;
    
  }
  .sidebar-navigation ul li {
     cursor: pointer;padding: 8px 0 0;
    transition: all ease-out 120ms;
  }
  .sidebar-navigation ul li i {
    display: block;
    font-size: 24px;
    transition: all ease 450ms;
  }
  .sidebar-navigation ul li .tooltip {
    display: inline-block;
    position: absolute;
    background-color: #313443;
    padding: 5px 15px;
    border-radius: 3px;
    margin-top: 8px;
    left: 90px;
    opacity: 0;
    display: none;
    left: -105px;
    z-index: 99999;
    opacity: 0!important;
    visibility: visible;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  .sidebar-navigation ul li .tooltip:before {
    content: "";
    display: block;
    position: absolute;
    right: -5px;
    top: 10px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: inherit;
  }
  /* .sidebar-navigation ul li:hover {
    background-color: #22252E;
  } */
  .sidebar-navigation ul li:hover .tooltip {
    visibility: visible;
    opacity:1 !important;
    display: block;
  }
  /* .sidebar-navigation ul li.active {
    background-color: red;
  } */
  .sidebar ul li.active:hover .tooltip{
    visibility: visible;
    opacity:1 !important;
    background-color: #313443;
  }
  .sideHead .online{
    right: 18px;
    top: 10px;
  }
  /* PAGINATION CSS BEGINS */
  .pagination{
    display: flex;
    justify-content: end; 
  }
   .pagination li{
    background: #fff;
    border: 1px solid #1e293b;
    border-radius: 50%;
    padding: 3px 12px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;    margin: 0 2px;
   }.pagination li a{
    text-decoration: none;    font-size: 13px;
    color: var(--darkClr);
   }.pagination li.active,.pagination li:hover{
    background: var(--darkClr);
   
   }
   .pagination li.active a, .pagination li:hover a{
    color: var(--whiteClr);
   }
   #selectFilter,#selectFilter li {
    font-size: 13px;
   }
  /* Language listing */
  .tableAction span{
    margin-left: 20px;
  }
  
.modal {
    /* visibility: hidden;
    opacity: 0; */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(77, 77, 77, .7);
    transition: all .4s;
  }
  
  .modal:target {
    visibility: visible;
    opacity: 1;
  }
  
  .modal__content {
    border-radius: 4px;
    position: relative;
    width: 650px;
    max-width: 90%;
    background: #fff;
    border: 1px solid #bbd2ef;
   }
   .modal__content h3{
    /* background: var(--darkClr); */
    background-color: #bbd2ef;
    font-size: 18px;
    color: #000;
    padding: 10px 15px;
   }
  .modalFields{
    padding: 5px 15px;
  }
  .modalFields label{
    font-size: 18px;
    display: flex;
    min-width: 135px;
    justify-content: end;
    align-items: center;
    padding-right: 10px;
  }
  .modalFields label span{
    padding-left: 10px;
  }
  .modalFields .form-group{
    margin-bottom: 10px; display: flex;
  }
  .modalFields .form-control{
    font-size: 18px;
  }
  .modal__footer {
    text-align: right;    padding: 15px
    
  }
  .modal__close {
    position: absolute;
    top: 7px;
    right: 15px;
    color: #fff;
    text-decoration: none;cursor: pointer;
  }
  .overflowHidden{
    overflow: hidden;
  }
  .overflowAuto{
    overflow: auto;
  }
  .form-control:focus {
     border-color: #1e293b80;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(30 41 59 / 27%);
}
.cursorPointer{
    cursor: pointer;
}
.categoryImg{
    width: 50px;
    height: 50px;
    object-fit: contain;
    overflow: hidden;
}
/* File upload */
.formInputSection {
    display: inline-block;
    width: 65%;
     vertical-align: top;
}
.fileUploadSec {
    display: inline-block;
}
/* .fileUploadSec input[type="file"] {
    display: none;
} */
.fileUploadSec label {
    border: 1px solid #8c8c8c;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    margin: 0;
}
.filUploadInline img {
    width: 35px;
    height: 35px;
    margin: 0 0 0 1px;
}.filUploadText {
    font-size: 12px;
    color: red;
    font-weight: 400;
    margin: 0 0 0 4px;
    display: inline-block;
}
.tabBooksList .dashBooksListTbl {
    margin-top: 40px;
}
.popOverlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background: rgba(247, 249, 250, .8); */
    background: #090a0acc;
    z-index: 0;
}
.popBack {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 5;
    overflow-y: auto;
}

.customizationPop {
    background-color: #fff;
    width: 700px;
    margin: 15px auto;
    border-radius: 5px;
    z-index: 1;
    position: absolute;
    padding: 20px 45px;
    left: 0;
    right: 0;
}

.customizationPopTitle {
    padding: 10px 0;
    position: relative;
    font-size: 24px;
    color: #205685;
    font-weight: 600;
    text-align: center;
    font-family: "Poppins SemiBold";
}

.customizationPopContent {
    margin: 20px 0;
    display: flex;
    min-height: 370px;
}
.popupClose{
    color: #205685;
    font-weight: 700;
    cursor: pointer;
}
.actionTbls{
    width: 180px;
    float: right;
}
.scheduleListNew{
    max-height: 400px;
      padding-right: 7px;
 }


.requestTable{
    border-collapse: collapse;
    border-bottom: 1px solid rgba(0,0,0,.2);
    max-width: 2000px;
    width: 97%;
    margin: 10px auto 20px;
    width: 100%;
    table-layout: fixed;
}
.requestTable thead{
    /* overflow-y: scroll; */
    padding-right: 0;
    display: block;
}
.requestTable thead tr, .requestTable tbody tr{
    width: 100%;
    display: flex;

}
.requestTable thead tr th, .requestTable tbody tr td{
    /* flex-basis: 100%;
    flex-grow: 2; */
    display: block;
    text-align: left;
    padding: 5px;
    /* width: 140px;  */
}
.requestTable tbody{
    display: block;
    width: 100%;
    overflow: auto;
    height: 350px;
    overflow-x: hidden;    padding-right: 1%;
}
.selectControl {
    font-size: 16px;
    min-width: 170px;
    position: relative;
    right: 15px;
}
.selectControlSe{
    font-size: 17px;
    min-width: 170px;
    position: relative;
    right: 0px;   
}
.descriptionData{
    width: 200px;
    word-break: break-word;
    display: -webkit-box;
    max-width: 100%;
    height: 46px;
    margin: 0 auto;
    font-size: 18px;
    line-height: 21px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;cursor: pointer;
}
.descrptn {
    font-size: 13px;
    margin: 0;
    width: 100%;
    height: 170px;
    overflow-y: auto;
}
.modalFieldss {
    padding: 5px 15px 15px;
}
input[type="file"]{
    font-size: 13px;
}
.btn-review{
    background-color: var(--blueClr);
    color: #fff;
    padding: 5px 20px;
    border: none;
    border-radius: 15px;
    margin-top: 8px;
}





.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 0px;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    /* background-color: #262538; */
  }
  .card h1 {
    font-weight: bold;
    font-size: 22px;
    color: #000;
    margin-bottom: 15px;
    padding-top: 10px;
  }
  .card form label {
    display: block;
    position: relative;
  }
  .card form label svg {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
  }
  .card form label input {
    width: 100%;
    padding: 15px 65px;
    /* background-color: #262538; */
    border: 1px solid #bab9c1;
    border-radius: 5px;
    color: #8c8aa7;
  }
  .card form label input:focus {
    outline: #49485d;
  }
  .card form label input::placeholder {
    color: #8c8aa7;
    font-size: 18px;
  }
  .card ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding-left: 0px;
    margin-bottom: 70px;
  }
  .card ul li {
    margin-top: 5px;
    margin-right: 2px;
  }
  .card ul li:first-child {
    margin-top: 5px;
    margin-right: 2px;
  }
  .card ul li a {
    color: #8c8aa7;
    border-radius: 10px;
    /* background-color: #1d1c2d; */
    text-decoration: none;
    display: block;
    padding: 10px 20px;
  }
  .card ul li a.active {
    background-color: #8780f8;
    color: #000;
  }
  .card .comment {
    /* background-color: #2c2b3f; */
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .card .comment_header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .card .comment_header-pic {
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #d56a6a;
    border-radius: 50%;
  }
  .card .comment_header h2 {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .card .comment_content {
    font-size: 16px;
    color: #000;
  }
  .card .comment_footer {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
  .card .comment_footer span {
    font-size: 14px;
    font-weight: 700;
    color: #8c8aa7;
  }
  .card .comment_footer button {
    background-color: transparent;
    padding: 8px 10px;
    border: 1px solid #1f1e2d;
    display: flex;
    align-items: center;
    color: #8c8aa7;
    border-radius: 3px;
  }
  .card .comment_footer button svg {
    margin-right: 8px;
  }
  .modal-dialog{
    max-width: 800px;
  }
  .card form label input::placeholder{
    font-size: 16px;
  }
  .modal-header{
    border-bottom: none;
  }
  .card .comment_footer span.replybtn{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 20px;
  }
  .reply-inputSec{
    margin: 5px 0px 15px;
  }
  .reply-inputSec .form-control{
    padding: 10px;
  }
  .replySubmtBtn{
    text-align: center;
    margin-top: 5px;
  }
  .reply-btn{
    background-color: var(--blueClr);
    color: #fff;
    font-size: 12px;
    font-size: 12px;
    padding: 4px 15px;
    border: none;
    border-radius: 10px;
  }
  .cancel-btn-reply{
    margin-left: 10px;
    background-color: #9694af;
  }
  .sub-commentSection{
    padding-left: 65px;
        padding-top: 15px;
  
  }
  .sub-commentSection .comment .comment_header {
    margin-bottom: 5px;
}
.sub-commentSection .comment  .comment_header h2 {
    font-size: 16px;
}
.sub-commentSection .comment .comment_content p{
    font-size: 14px;
    margin-bottom: 6px;
}
.sub-commentSection .comment .comment_footer span {
    font-size: 12px;
}
.starWriters span{
    padding: 0px 10px;
    border: 1px solid var(--darkClr);
    margin-right: 8px;cursor: pointer;
    width: 54px; 
    display: inline-block;
    text-align: center;
}
.starWriters span.active,.starWriters span:hover{
    background-color: var(--darkClr);
    color: var(--lightBgClr);
}
.booksList-Wrapper{
    padding: 20px 30px 10px;
}
.booksList-EditWrapper{
    background-color: #fff;
    border-radius: 25px;
    padding: 50px 20px
}
.blogListSec{
    width: 48%;
    margin: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    padding: 15px;
    height: 340px;
    position: relative;
    background: #fff; cursor: pointer;
}
.blog-listrow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blog-description{
    display: -webkit-box !important;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 15px;    margin-bottom: 10px;
    height: 90px;    margin-top: 8px;
}
.blog-head{
    font-size: 26px;
    font-weight: 600;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;    height: 65px;
}
.blog-author{
    color: #979191;
    margin-bottom: 1px;
}
.blog-content-center{
    align-items: center;
}
.blog-updatedate{
    font-size: 14px;
}
.blog-viewDetail{
    color: rgb(106, 91, 91);
    font-size: 14px;
    margin-right: 10px;
}
.blog-icons{
    /* position: absolute;
    bottom: 13px;
    right: 20px; */
    cursor: pointer;
}
.editiconBlog{
    color: rgb(106, 91, 91);
    margin-right: 10px;
    margin-right: 10px;
    font-size: 17px;
}
.deleteIconBlog{
    font-size: 17px;
    color: rgb(219, 0, 0);
}
.bio{
    font-size: 11px;
    border: 1px double #dddddd;
    padding: 5px 8px;
    border-radius: 3px;
    /* width: 94%; */
    width:80%;
    display: block;
}
.alignCenter{
    display: flex;
    align-items: center;    position: relative;justify-content: space-between;    word-break: break-word;
}
.faEdit{
    position: absolute;
    right: 5px;
    color:var(--darkClr);
    cursor: pointer;
}
.proPic {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin: 5px;
    margin-right: 8px;
    object-position: top;
}
.starWriterListoverflw{
    max-height: 390px;
    overflow-y: auto;
    position: relative;
}
.starWriterListoverflw thead{
    position: sticky;
    width: 100%;
    top: 0;
    background: #fff;
    box-shadow: 0 0px 1px 0px #dddddd
}
.removeBtn{
    color: red;
    font-size: 16px;
    border: 1px solid red;
    padding: 2px 10px;
    border-radius: 10px;cursor: pointer;
}
.startWriterBtn {
    color: #000;
    font-size: 15px;
    border: 1px solid #bbd2ef;
    padding: 2px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.startWriterBtn:hover{
    background-color: #bbd2ef;
    color: #000;
}
.budgetTable.starWriterListoverflw table{
    margin-top: 0px;
}
#authorSave {
    display: block;
    border: 1px solid green;
    font-size: 12px;
    padding: 1px 10px;
    border-radius: 4px;
    color: green;
}
.AuthrsListWrap .faEdit {
    position: absolute;
    right: 35px;    margin-top: -10px;
}
.main-bookHead{
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 10px;
}
.book-description{
    padding-top: 30px;
    text-align: left;
}
.select-language{
    text-align: left;
    font-family: 'Poppins-Regular';
}
.btn-saveBook{
    padding: 6px 25px;
    color: #000;
    border: none;
    border-radius: 11px;
    margin: 20px 5px;

}
.description-editor .rdw-editor-main{
    border: 1px solid #f1f1f1;
    padding: 10px;
}
.WrapRight svg.svglogout {
    font-size: 27px;
    color: #64748b;
    cursor: pointer;
    padding: 2px;
    margin: 33px 4px;
}
.summaryTabs.budgetTable{
    background-color: #fff;
}
.loginWrap .headerMenus ul li{
    visibility: hidden;
}
.WrapLeft.loginWrap{
    /* width: 99.1%; */
    width: 100%;
    border: none;
}
svg{
    font-size: 18px;cursor: pointer;
}
.modal__close svg{
    color: #000;
}
.dashAction a.btnGrey {
    background: #c3a6a6;
    color: #000;
}
.dashAction a.btnGrey:hover {
    background: #ddd;
 }
 .backToLink{
    cursor: pointer;
    text-decoration: underline;
}
.mainHead-wrapper{
    padding: 0 13px;
}
.search-bookinput{
    width: 100%;
    text-align: left;
    border: 1px solid #d1d1d1;
    padding: 8px 12px;
    border-radius: 6px;
}
.search-bookinput:focus{
    outline: unset;
}
.btn-search{
    border: unset;
    padding: 8px 20px;
    border-radius: 10px;
}
.issueSummWrap.tabBooksList .react-tabs__tab-panel{
    position: relative;
    top: 25px;
}.authr{
    font-size: 17px;
    margin: 0;
    font-weight: 600;
    text-transform: capitalize;
}
.justify-content-between{
    align-items: center;
}
.hrBorder{
    margin: 5px;border-style: dashed;
}
.bookListFilter{
display: flex;
justify-content: end;    

}
.paddRight{
    padding-right: 0;
} 
 .blog-content-center img{
    width: 112px;
    height: 160px;
    object-fit: contain;    object-position: center;
 }
 .fotgotpass-wrapper{
    width: 500px;
    margin: 50px auto;
    border-radius: 15px;
    box-shadow: 0 3px 6px #ccc;
    padding: 50px;
}
.fotgotpass-wrapper .form-group {
    margin-bottom: 30px;
}
.fotgotpass-wrapper .form-group label{
    padding-bottom: 10px;
}
.forgotPass-btn{
    border: none;
    padding: 8px 25px;
    border-radius: 15px;
    background-color: #ff5900;
    color: #fff;
    font-weight: 500;
}
.fotgotpass-wrapper .form-group .form-control{
    padding: 10px 13px;
    background: transparent;color: #fff;
}
.not-valid-sec{
    width: 600px;
    margin: 80px auto;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding: 40px;
}
.bottomSec{
    position: absolute;
    bottom: 35px;
    width: 80%;
    text-align: left;
    padding-left: 15%;
}
.termscond-right{
    margin-right: 20px;
}
.bottom-secText{
    color: #fff;
    /* color: #000; */
    font-size: 14px;
    text-decoration: none;
    font-family: 'Poppins-Light';
    text-shadow: 0 0 #fff;
}
.darkGrn h1, .darkGrn p{
    color: #6da700;
}
.darkSkybl h1, .darkSkybl  p {
    color: #00a0ff;
}
.darkligG h1,.darkligG p{
    color: #149354;
}
.darkpur h1,.darkpur p{
    color: #691ccb;
}
.draftCon{
    display: flex;
    justify-content: left;
    align-items: center;
}
.draftCon p{
    margin: 0px;
    padding-left: 10px;    display: flex;align-items: center;
}
.draftCon p svg{
    margin-right: 5px
}
.tabBooksList .categoryImg {
    /* width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 4px;
    cursor: pointer; */
    width: 130px;
    height: 130px;
    object-fit: contain;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    /* box-shadow: 0 3px 6px #dee9f7;
    border: 2px solid #dee9f7; */
    object-fit: cover;
}
.dflexAlignCenter{
    display: flex !important;
    align-items: center;
}
.dflexAlignCenterEnd{
    display: flex !important;
    align-items: center;
    justify-content: end;
}
.tabBooksList .btn-review{
    margin-top: 0px;
}
.topHead{
    margin-bottom: 0px;
    font-size: 18px;
    font-family: 'Poppins-Medium';
    margin-top: 15px;
}
.dashCardsBooks{
    text-align: center;
}
.dashCardsBooks h4{
    font-size: 16px;    margin: 0px;
    margin-bottom: 3px;
    
}
.dashCardsBooks h4 svg{
    margin-right: 5px;
}
.dashCardsBooks img{
    border-radius: 4px;
    /* box-shadow: 0 3px 6px #ccc; */
    margin: 8px 0;    width: 100%;margin-top: 0px;
}
.latestRTitle {
    font-size: 16px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.profile-imgBook{
    width: 100px;
    height: 100px;
}
.authordetails-wraper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 20px;
    text-align: center;
}
.author-details-wraper{
    margin-left: 20px;
}
.author-headtext{
    font-size: 26px;
    font-weight: 600;

}
.author-subtext{
    font-size: 16px;
    margin-bottom: 4px;
}
.followers-wraper{
    display: flex;
    justify-content: center;
}
.follow-subdiv{
    margin:10px
}
.follow-number{
    font-weight: 700;
}
.follow-text{
    font-size:12px;
    font-weight: 500;
}

/*  */

.text {
    font-family: inherit;
    font-weight: 700;
    line-height: inherit;
    text-rendering: optimizeLegibility;
  }
  .text-title {
    font-size: 17px;
    color: #d32f2f;
    margin: 0;
    margin-top: 5px;
  }
  
  .paragraph {
    font-family: inherit;
    font-size: 1rem;
    font-weight: normal;
    line-height: inherit;
    margin: 0.25rem 0;
    color: #252a32;
    text-transform: unset;
    text-rendering: optimizeLegibility;
  }
  
  .truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-width: 100%;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .main .scroll {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 1rem 0;
    margin: 0.5rem 0;
    cursor: default;
    overflow: scroll hidden;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    scroll-padding: 0px 1.25rem;
    scrollbar-width: none;
    margin-left: -20px;
  }
  .main .scroll::-webkit-scrollbar {
    display: none;
  }
  .main .scroll.active {
    cursor: grab;
    cursor: -webkit-grab;
  }
  .main .scroll .card {
    width: 13rem;
    height: auto;
    flex: 0 0 auto;
    margin: 0 0.5rem;
    border: none;
    outline: none;
    border-radius: 15px;
    color: #252a32;
    background: transparent;
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
    cursor: pointer;
  }
  .main .scroll .card-image {
    position: relative;
    display: block;
    width: 180px;
    height: 265px;
    padding-top: 110%;
    margin: 0 auto;
  }
  .main .scroll .card-image img.responsive {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .main .scroll .card-inner {
    width: 100%;
    height: auto;
    padding: 10px 15px;
  }
  .starRatingCount {
    background: #056974;
    position: absolute;
    top: 8px;
    right: 22px;
    padding: 1px 8px;
    font-size: 14px;
     margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;color: #fff;border-radius: 4px;box-shadow: 0 3px 6px #012d32db;
  }
  .starRatingCountLabel{
    background: #056974;
    position: relative;
    top: 0;
    left: 15px;
    padding: 1px 8px;
    font-size: 14px;
     margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;color: #fff;border-radius: 4px;box-shadow: 0 3px 6px #012d32db;
 
  }
  .starRatingCountTbl{
    background: #056974;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 1px 8px;
    font-size: 14px;
     margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;color: #fff;border-radius: 4px;box-shadow: 0 3px 6px #012d32db;
  }
  .episodesCount {
    position: absolute;
    left: 2px;
    bottom: 0;
    /* background: #00869d; */
    color: #fff;
    font-size: 12px;
    padding: 6px 5px;
    border-radius: 3px;
    /* box-shadow: 0 3px 6px #380303; */
    background-image: linear-gradient(to top, #111827, transparent);
    margin: 0;
    width: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .episodesCountBtm {
    position: absolute;
    bottom: 6px;
    left: 8px; 
    color: #fff;
    font-size: 14px;
    padding: 1px 5px;
    border-radius: 3px;
    box-shadow: 0 3px 6px #380303;
  }
.blogP p{
    display: flex;
    justify-content: left;
    align-items: center;
}
.blogP p svg{
    margin-right: 8px;
}
.practiceContentText  {
    width: 100%;position: relative;
}
svg.FaCalendar {
    position: absolute;z-index: 9;    right: 15px;
    margin-top: 10px;
}
.practiceContentText .react-datepicker-wrapper {
     width: 100%;
}
.starRatingCountBkDetl {
    background: #056974;
    position: absolute;
    top: auto;
    right: 8px;
    padding: 1px 8px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 6px #012d32db;
    margin-top: 8px;
}
.bookDet .img-fluid {
    max-width: 100%;
    height: auto;
    position: relative;
    width: 160px;
    height: 160px;
    object-fit: contain;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 3px 6px #fff;
    border: 2px solid #fff;
}
.episodesCountBkDet {
    position: absolute;
    left: 8px;
    bottom: -60px;
    /* top: 134%; */
    background: #00869d;
    color: #fff;
    font-size: 12px;
    padding: 1px 5px;
    border-radius: 3px;
    box-shadow: 0 3px 6px #380303;
}
.blogListSec .blog-content-center img {
    /* object-position: center; */
    /* max-width: 100%;
    width: 160px;
    height: 160px;
    object-fit: contain;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 3px 9px #83838382; */
    max-width: 100%;
    object-fit: cover;
    border-radius: 4px;
    position: relative;
    box-shadow: 0 3px 9px #83838382;
    width: 180px;
    height: 265px;
}
.blogListSec .blog-content-center .starRatingCountTbl {
    background: #056974;
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 1px 8px;
    font-size: 14px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 6px #012d32db;
}
.episodeInfo h2{
    font-size: 18px; cursor: default;
}
.episodeInfo .col-md-6 h2{
    cursor: pointer;
}
.episodeViews span {
    margin-right: 20px;
    font-size: 14px;
}
.episodeViews{
    margin: 0px;
    padding-left: 10px;
}
.episodeBg {
    background: #fff;
    display: flex;
     padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    /* cursor: pointer; */
}
.dEnd {
    display: flex;
    justify-content: end;
    padding: 0px;
}
.dEnd h2{
    font-size: 16px;
}
.blogP p svg{
    font-size: 16px;color: #212529;
}
.blogP .blog-author{
    color: #212529; font-weight: 600;
}
.bkListSpan {
    position: relative;
    max-width: 100%;
    /* width: 160px;
    height: 160px; */
    display: block;
}
#demo-modal .react-datepicker-wrapper{
    width: 100%;
}
.bkBg {
    background: #fff;
    padding: 15px;
    box-shadow: 0 0px 6px #c5c4c4;
    border-radius: 15px;
}
.bookDetOLDDD img.img-fluid{
    width: 100%;
    height: 365px;
    object-fit: cover;
}
.vLine{
    margin: 0 10px;color: rgb(2 104 135);
}
.dCenterBk{
    display: flex;
    align-items: center;
}
.episodeWrap h2{
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}
.notificationWrap .form-group{
    margin-bottom: 15px;position: relative;
}
.notificationWrap [type="radio"]:checked,
.notificationWrap [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.notificationWrap [type="radio"]:checked + label,
.notificationWrap [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;margin-right: 40px;
}
.notificationWrap [type="radio"]:checked + label:before,
.notificationWrap [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.notificationWrap [type="radio"]:checked + label:after,
.notificationWrap [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--blueClr);
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.notificationWrap [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.notificationWrap [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.notifiSec{
    display: flex;
}
.notiSubmit {
    font-size: 18px;
    border: none;
    border-radius: 6px;
    padding: 7px 20px;
    display: inline-flex;
    align-items: center;
    margin-left: 15px;
     text-decoration: none;
    cursor: pointer;
    min-width: 120px;
    justify-content: center;
    color: #000 !important;    background-color: #a7c5eb;
    position: absolute;
    right: 0;top: -15px;
}
.notSub{
    display: flex;
    justify-content: end;
}
.notifiSec p{
    margin: 0px;
}
.notiSelect { /*container for custom dropdown arrow*/
    -webkit-appearance: none;
    -moz-appearance: window;
    padding: 4px 35px 4px 10px;
    background-image: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
   background-repeat: no-repeat;
   background-position: 95% 8px;
  }
  .forgotPasswrdWrsp.WrapLeft.loginWrap {
    width: 100%;
     background: var(--themeColor) !important;
    height: 100vh !important;    display: flex;
    justify-content: center;
    align-items: center;
}
.forgotPasswrdWrsp .fotgotpass-wrapper .form-group label {
     color: #fff;
}
.forgotPasswrdWrsp .form-control:focus {
     outline: 1px solid #fff;
     background-color: transparent;
}
.datePickerWrap .react-datepicker{
    background-color: #de4200;
}
.login-wrapper .datePickerWrap  button{
    width: auto;
}
.datePickerWrap .form-control:focus {
     background-color: transparent;
    box-shadow: none;
    outline: none;
}
#loginPopup1 {
    width: 600px;
}
#loginPopup1 .login-wrapper .inputbox.col-md-6 {
     margin: 15px 0;
     width: 48%;
     margin: 8px auto;    padding: 0;
}
#loginPopup1 .login-wrapper .inputbox.col-md-12{
    width: 98%;
     margin: 15px auto;  padding: 0;
}
#loginPopup1 .react-datepicker__input-container .form-control{
    position: relative;
    left: 50px;
    top: 5px;  
}
#loginPopup1 .login-wrapper {
    padding: 5px 33px;
}
#loginPopup1 .faTimesRight {
    position: absolute;
    top: -40px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #de4200 !important;
    color: #fff;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #de4200a8 !important;
    color: rgb(0, 0, 0);
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    cursor: default;
    color: #5f5f5fd6 !important;
}
.datePickerWrap .react-datepicker-wrapper {
    display: block ;
    width: 90% ;
    float: inline-end;
}
.sub-commentSection .edit-revewwrapIcon {
    margin-left: 15px ;
}
.chapter-outer .episodeBg{
    margin: 10px auto;
}
/* ==================== ALBY'S CSS BEGINS HERE ============================ */

.review-wrapper input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.review-wrapper label {
	cursor: pointer;
	text-indent: -9999px;
	width: 45px;
	height: 20px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.review-wrapper label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 5px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.review-wrapper input:checked + label {
	background: var(--blueClr);
}

.review-wrapper input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.review-wrapper label:active:after {
	width: 15px;
}
/* .edit-revewwrap{
    justify-content: space-between;
} */
.edit-revewwrapIcon{
    margin-left: 0px;
}
.edit-svg{
    color: rgb(106, 91, 91);
    margin-right: 15px;
    font-size: 16px;
}
.delete-svg{
    color: rgb(219, 0, 0);
    font-size: 14px;
}
.comment-toggle{
    justify-content: right;
    align-items: center;
    /* margin-bottom: 18px; */
    font-size: 14px;
}
.revwsubmt-bottm-sec{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
label.btn-upload {
    padding: 6px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 10px 4px 0px;
    cursor: pointer;
    background-color: white;
    color: black;
    border: 2px solid #008CBA;
    border-radius: 8px;
  }
  label.btn-upload.padd-upload{
    padding: 6px 20px;
  }
  .status-btn{
    border-radius: 5px;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0;
    cursor: pointer;
  }
  .status-unapprovedbtn{
    background: #ffe0e0;
    color: #ff1616;
  }
 .status-approvedbtn{
    background-color: #a8f796;
    color: #008033;
 }
 .status-draftbtn{
    background-color: #c0c0c0;
    color: #5f6c7c;
 }
 .booklist-wrapper{
    background: var(--whiteClr);
    padding: 20px 20px;
    border-radius: 7px;
    box-shadow: 0 3px 6px #ccc;
    margin: 10px;
    width: 48%;
    display: inline-block;
    min-height: 215px;
    vertical-align: top;
    position: relative;

 }

 .booklist-leftSec{
    display: flex;
    /* align-items: center; */
    
 }
 .boolkist-left-btm{
    display: flex;
    justify-content: space-between;
 }
 .book-list-detailsec p{
    margin-bottom: 3px;    display: flex;
    align-items: center;
 }
 .book-list-detailsec p svg{
    margin-right: 5px;
 }
 .book-list-detailsec{
    padding-left: 20px;
    width: calc(100% - 130px);
 }
 .booklist-img-div{
    width: 130px;
 }
 .book-list-headText{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow : hidden;
    max-width: 260px;
    line-height: 1.5;
    margin-bottom: 10px;
    cursor: pointer;
 }
 .book-episode-sections{
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-top: 10px;
 }
 .booklist-headersec{
    background: var(--whiteClr);
    box-shadow: 0 3px 6px #ccc;
    padding: 20px 20px;
    border-radius: 7px;
    margin: 15px 10px;
 }
 .book-list-outer-bg{
    background: var(--whiteClr);
    box-shadow: 0 3px 6px #ccc;
    padding: 20px 20px;
    border-radius: 15px;
    margin: 15px 0;
 }
.tag {
    position: absolute;
    display: inline-block;
    border-radius: 6px;
    clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
    background: #545081;
    padding: 6px 23px;
    margin: 0 8px;
    font-weight: 600;
    font-size: 12px;
    color: var(--color-text);
    transition: clip-path 500ms;
    top: 8px;
    right: 0;
  }
  
  .tag:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background: var(--color-back);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 6px 0;
    transition: transform 500ms;
  }
  
  .tag:hover {
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 0px);
  }
  
  .tag:hover:after {
    transform: translate(-100%, -100%);
  }
  .approved-tag{
    background-color: #a8f796;
    color: #008033;
  }
  .draft-tag{
    background-color: #c0c0c0;
    color: #5f6c7c;
  }
  .unapproved-tag{
    background: #ffe0e0;
    color: #ff1616;
  }
  .dEnd-dateTime{
    display: flex;
    align-items: center;
  }
  .dEnd-dateTime h2{
    font-size: 15px;
  }
  .selectControl-status{
    font-size: 14px;
    min-width: 155px;
    position: relative;
    right: 0;
  }
  .chapter-outer .tag{
    padding: 4px 22px;
    margin: 2px 2px;
    font-size: 10px;
  }
  .chapter-outer .episodeBg{
    padding: 32px 15px;
  }
  .episodeInfo h2.episode-date{
    font-size: 15px;cursor: default;
  }
  .add-modal-footer{
    display: flex;
    justify-content: flex-end;
}
/* ==================== ALBY'S CSS ENDS HERE ============================ */
#buttonArrowAdmin {
    display: inline-block;
    top: 35%;
    position: fixed;
     z-index: 1000;
     border-radius: 50%;
    background: #de4200;
    padding: 2px 5px;
    left: 30px;
}
.episodeViews span svg{
    cursor: default;
}
._darkMode_ .css-1dimb5e-singleValue, ._darkMode_   .myProfileBkDet .card h1
/* ._darkMode_ .card .comment_footer span */
/* ._darkMode_ .reply-inputSec .form-control, ._darkMode_ .reply-inputSec .form-control:focus */
{
color: #000 !important ;
}
.review-wrapper111111 .comment_footer span, .review-wrapper111111 .reply-inputSec .form-control, .review-wrapper111111 .reply-inputSec .form-control:focus {
    color: #000 !important;
}

.comment_content .edit-revewwrapIcon {
    margin-left: 15px;
}
._darkMode_ .review-wrapper111111 .comment-toggle.commntRply {
    color: #000 !important;
}
.review-wrapper12 .card .comment_footer span,
.review-wrapper12 .reply-inputSec .form-control, .review-wrapper12 .reply-inputSec .form-control:focus,
.review-wrapper12  .revwsubmt-bottm-sec .comment-toggle.commntRply{
    color: #000 !important; 
}
.episodesCount svg{
    margin-right: 5px;
}
.noDataSec{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 13px;color: red;font-weight: bold;    padding: 30px;
}

.modalFields .form-group.userAct {
    margin-bottom: 10px;
    display: block;
    max-height: 200px;
    overflow-y: auto;
}


