:root {
    --themeColor: #1e293b;

}


@font-face {
    font-family: 'Poppins-Bold';
    src: url('../fonts/Poppins-Bold.ttf');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('../fonts/Poppins-Light.ttf');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url("../fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: 'Forum-Regular';
    src: url('../fonts/Forum-Regular.ttf');
}

body {
    font-family: 'Poppins-Regular';
}

.header-home {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0px;
}

.slick-arrow.slick-next {
    right: 50px;
    background: transparent;
    border: none;
    font-size: 0;
    width: 40px;
    height: 40px;
    z-index: 99;
    opacity: 1;
}

.slick-arrow.slick-next:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url("../images/next-arrow.png");
    background-size: cover;
    opacity: 1;
}

.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url("../images/next-arrow-bg.png");
    background-size: cover;
}

.slick-arrow.slick-prev {
    left: 50px;
    background: transparent;
    border: none;
    font-size: 0;
    width: 40px;
    height: 40px;
    z-index: 99;
    opacity: 1;
}

.slick-arrow.slick-prev:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url("../images/back-arrow.png");
    background-size: cover;
    opacity: 1;
}

.slick-prev:hover:before,
.slick-prev:focus:before {
    opacity: 1;
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url("../images/back-arrow-bg.png");
    background-size: cover;
}

.bannerImg {
    position: relative;
}

.bannerImg::before {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    /* z-index: 1; */
    background: rgba(0, 0, 0, 0.8);
}

.bannerContent h3 {
    color: #fff;
    font-size: 26px;
    font-weight: 400;
    margin: 0 0 21px;
    text-transform: none;
    font-family: 'Poppins-Medium';
}

.bannerContent h2 {
    color: #ff7236;
    font-size: 52px;
    margin: 0 0 14px;
    text-transform: uppercase;
    font-family: 'Poppins-SemiBold';
}

.bannerContent p {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 auto 30px;
    max-width: 650px;
    color: #fff;
    font-family: 'Poppins-Regular';
}

.bannerContent {
    bottom: initial;
    left: 10%;
    max-width: 100%;
    right: 10%;
    text-align: center;
    top: 50%;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: auto;
    position: absolute;
}

.categorySection {
    background-attachment: fixed;
    background-image: url("../images/category-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 92.5px 0 100px;
}

.section-title {
    color: #fff;
    font-size: 35px;
    text-transform: uppercase;
    font-family: 'Poppins-Medium';
}

.underline.center {
    background-position: center top;
}

.underline {
    background-image: url("../images/underline-orange.png");
    background-repeat: no-repeat;
    float: left;
    height: 13px;
    margin: 4px 0 5px;
    width: 100%;
    background-size: contain;
    object-fit: contain;
}

.lead {
    color: #cfcfcf;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 1.42857143;
    margin-bottom: 50px;
    font-family: 'Poppins-Medium';
}

.category-tab .content-center {
    text-align: center;
}

.category-tab .react-tabs__tab {
    background-color: #3e3e3e;
    border: 0;
    color: #fff;
    font-size: 12px;
    margin: 0 3px 10px;
    padding: 11px 30px;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    width: auto;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    font-family: 'Poppins-Medium';

}

.category-tab .react-tabs__tab:hover {
    background-color: #ff7236;
}

.category-tab .react-tabs__tab.react-tabs__tab--selected {
    background-color: #ff7236;
    border-radius: unset;
    border: none;
    color: #fff;
    font-size: 12px;
    margin: 0 3px 10px;
    padding: 11px 30px;
    font-family: 'Poppins-Medium';
}

.category-tab .react-tabs__tab:focus:after {
    position: unset;
}

.category-tab .react-tabs__tab-list {
    border-bottom: unset;
    margin: 0 0 40px;
}

.content-center {
    text-align: center;
}

.categoryTabList ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    /* float: left; */
    width: 25%;
    display: inline-block;
    list-style-type: none;
    position: relative;
}

.categoryTabList ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.viewmore-btn {
    font-size: 16px;
    padding: 10px 28px 11px;
    text-transform: uppercase;
    color: #fff;
    background-color: #ff7236;
    text-align: center;
    margin-top: 50px;
    border: none;
    transition: all 0.3s ease-in-out;
}

.viewmore-btn:hover {
    background-color: #de4200;
}

.bg-orange {
    background-color: rgba(255, 114, 54, 0.9);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    width: 100%;
    position: absolute;
    top: 100%;
    text-align: center;
    color: #fff;
    height: 100%;
    display: block;
}

.bg-navarsa {
    /* background-color: rgba(255, 114, 54, 0.9); */
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    width: 100%;
    position: absolute;
    top: 100%;
    text-align: center;
    color: #fff;
    height: 100%;
    display: block;
}

.bg-latest-release {
    background-color: rgba(255, 114, 54, 0.9);
    opacity: 0;
    /* -webkit-transition: all 0.3s ease-in-out; */
    /* transition: all 300ms ease-in; */
    width: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    color: #fff;
    height: 100%;
    display: block;
    transition: all 0.3s ease-in-out 0s;
}

.info-block {
    left: 0;
    padding: 0 30px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.categoryTabList ul li:hover .bg-orange {
    opacity: 1;
    /* top: 0; */
}

.section-navarasa .card:hover .bg-navarsa {
    opacity: 1;
    top: 0;
}

.section-latestRelease ul .li:hover .bg-latest-release {
    opacity: 1;
    top: 0;
}

.info-block .texthead {
    color: #fff;
    margin: 0 0 7px;
    text-transform: none;
    font-size: 18px;
    font-family: 'Poppins-Medium';
    font-size: 24px;
}

.info-block .author {
    display: block;
    margin: 0 0 5px;
    font-size: 14px;
}

.info-block .readmore {
    color: #fff;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

.info-block .readmore:hover {
    color: #000;
}

.readmore svg {
    font-size: 12px;
}

.strong-text {
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
}

.purchase-btn {
    color: #3e3e3e;
    background-color: #fff;
}

.purchase-btn:hover {
    background-color: #3e3e3e;
    color: #fff;
}

.language-section {
    margin-top: -114px;
    padding: 0;
    position: relative;
    /* z-index: 9; */
}

/* .languageSection-box {
    background: linear-gradient(
        rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../images/istockphoto-1395058135-640x640.jpg") center center no-repeat;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px;
    width: 100%;
} */


.languageSection-box {
    padding: 15px;
    width: 100%;
    position: relative;
    overflow: hidden;
    /* position: relative; */
    /* overflow: hidden; */
    /* width: 100%; */
    /* height: 100vh; */
    /* background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat center center/cover; */
}

.languageSection-box video {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: 960px;
    opacity: 0.9;
    /* z-index: 1;  */
}

.languageSection-box .overlay {
    width: 100%;
    height: 960px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, .7) 50%);
    background-size: 3px 3px;
    /* z-index: 2; */
}










.lang-category-text {
    font-size: 16px;
    color: #fff;
    font-family: 'Poppins-Medium';
    /* opacity: 0.9; */
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
}

.languageSection-box a {
    text-decoration: none;
}


.lang-border {
    /* border: 1px solid #de4200; */
    padding: 35px 15px;
    /* box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5), -4px -4px 16px rgba(255, 255, 255, 0.05); */
    /* border-radius: 10px; */
    position: relative;
}

.languageSelect-mainHead {
    color: #de4200;
    text-align: center;
    text-transform: capitalize;
    font-family: 'Poppins-Medium';
    font-size: 32px;
    padding-bottom: 20px;
    font-weight: 400;
}

.languageSelect-mainHead span {
    color: #fff;
    font-size: 35px;
    text-transform: uppercase;
    font-family: 'Poppins-Medium';
}

.lang-box {
    /* width: 120px; */
    /* border-radius: 0.75rem;
    background-color: #fff;
    padding: 1.25rem;
    color: #de4200;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
    margin: 10px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    min-width: 130px;
    text-align: center;
    text-decoration: none; */
    display: flex;
    width: 170px;
    height: 170px;
    justify-content: center;
    align-items: center;
    /* background: url(http://samuel-garcia.site/img/clock-sam.png); */
    /* background-size: cover; */
    border: 4px solid #191919;
    border-radius: 10px;
    box-shadow: -4px -4px 10px rgba(67, 67, 67, 0.5),
        inset 4px 4px 10px rgba(0, 0, 0, 0.5),
        inset -4px -4px 10px rgba(67, 67, 67, 0.3),
        4px 4px 10px rgba(0, 0, 0, 0.3);
    margin: 10px;
}

.lang-box img {
    width: 140px;
    height: 140px;
    object-fit: cover;
    cursor: pointer;
    /* -webkit-transition: 0.4s ease; */
    /* transition: 0.4s ease; */
}



/* .image-card{
    box-shadow: 0 30px 60px 0 rgb(222 66 0);
} */

/* .zoom-effect-container:hover .lang-box img {
    -webkit-transform: scale(1.09);
    transform: scale(1.09);
} */

/* .lang-box:hover {
    background-color: #de4200;
    color: #fff;
} */

.lang-letter {
    font-size: 36px;
    text-align: center;
    text-transform: capitalize;
}

.section-topbBook ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.section-topbBook.section-latestRelease ul .li {
    height: 305px;
    /* overflow: hidden; */
    border: 1px solid #ffffff3b;
    width: 99% !important;
}

.categoryTabList .content {
    border: 1px solid #ffffff3b;
    margin: 2px 5px;
}

.section-topbBook ul li img,
.categoryTabList .img-fluid {
    width: 100%;
    height: 300px;
    object-fit: fill;
}

.section-topbBook.section-latestRelease ul .li img {
    object-fit: contain;
    height: 300px;
    overflow: hidden;width:100%;
}
.section-topbBook.section-latestRelease.libraryWrap ul .li { 
    border: 1px solid #7c7c7c3b;
}
/* .libraryWrap .bookNameReleased{
    cursor: pointer;
} */
.section-topbBook {
    padding: 100px 0;
}

.section-topbBook ul li.list-top:hover .bg-topBook {
    opacity: 1;
    top: 70%;
}

.section-topbBook ul li.list-top .bg-topBook h4 {
    font-size: 18px;
    font-family: 'Poppins-Medium';
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15em;
    line-height: 1.5;
}

.section-topbBook ul li.list-top .info-block {
    top: 32px;
}

.rating-div {
    position: absolute;
    top: 8px;
    z-index: 4;
    color: #fff;
    right: 7px;
    display: flex;
    align-items: center;
    background-color: rgba(222, 66, 0, 0.9);
    padding: 1px 5px;
    border-radius: 6px;
}

.rating-div-text {
    font-size: 12px;
    padding-left: 4px;
}

.rating-div svg {
    font-size: 13px;
}

.marquee-item:hover .content-block {
    opacity: 1;
    top: 0;
}

.bg-topBook {
    background-color: rgba(222, 66, 0, 0.9);
    /* background: rgba(0,0,0,0.7); */
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    width: 100%;
    position: absolute;
    top: 100%;
    text-align: center;
    color: #fff;
    height: 100%;
    display: block;
    cursor: pointer;
}

.episode-partText {
    font-size: 12px;
    font-family: 'Poppins-Medium';
    position: absolute;
    top: 32px;
    z-index: 4;
    color: #fff;
    right: 7px;
    display: flex;
    align-items: center;
    background-color: rgba(222, 66, 0, 0.9);
    padding: 1px 5px;
    border-radius: 6px;
}

.episode-partText svg {
    font-size: 14px;
    margin-right: 4px;
}

.content-block {
    background-color: rgba(255, 114, 54, 0.9);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    width: 100%;
    position: absolute;
    top: 100%;
    text-align: center;
    color: #fff;
    height: 100%;
    display: block;
}

.mainHeadText {
    color: #000;
    font-size: 38px;
    text-transform: uppercase;
    font-family: 'Poppins-SemiBold';
    /* margin-bottom: 30px; */
    text-align: center;
}

.headdiv-sec {
    margin-bottom: 45px;
}

.section-navarasa {
    background-attachment: fixed;
    background-image: url("../images/home-v1-news-event-banner.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.mainHeadText-white {
    color: #fff;
}


.marquee-container {
    overflow: hidden;
}

.marquee {
    display: flex;
    overflow: hidden;
}

.marquee-content {
    display: flex;
    animation: marquee-slide 10s linear infinite;
}

.marquee-items {
    display: flex;
}

.marquee-item {
    flex: 0 0 14vw;
    margin: 0 1vw;
}

.marquee-item img {
    display: block;
    width: 100%;
}

@keyframes marquee-slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}







.section-latestRelease {
    background-color: #272A30;
}

.section-starWriter {
    padding: 100px 0;
}

.list-top {
    display: inline-block;
    width: 18%;
    margin: 10px;
    vertical-align: top;
    /* border-radius: 1rem; */
    /* height: 200px; */
    height: auto;
    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.66);
    position: relative;
    background-color: #000;
}

.list-top:after {
    display: block;
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    /* z-index: 1; */
    border-radius: 10px;
}

.list-top img {
    width: 100%;
    object-fit: fill;
    /* border-radius: 1rem; */
    height: 100%;
    transition: .3s ease-in-out;
}

.list-top img:hover img {
    opacity: .7;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0) scale(1.1, 1.1)
}

/* .list-top-last{
    background-color: #3E434C;
    width: 200px;
    height: 200px;
    position: relative;
  } */
.list-more {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.select-status-sec {
    margin-left: 20px;
}

.shine-overlay {
    position: relative;
    overflow: hidden;
}

.shine-overlay:hover .shine {
    display: block;
}

.shine-overlay {
    position: relative;
    overflow: hidden;
}

.shine-overlay:hover .shine {
    display: block;
}

.shine {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    background: -webkit-gradient(linear,
            left top,
            right top,
            from(rgba(255, 255, 255, 0)),
            color-stop(50%, rgba(255, 255, 255, 0.8)),
            to(rgba(255, 255, 255, 0)));
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    transform: skew(30deg);
    animation: shine 0.75s linear 1;
}

@keyframes shine {
    0% {
        left: -50%;
        opacity: 0;
    }

    50% {
        left: 25%;
        opacity: 0.7;
    }

    100% {
        left: 100%;
        opacity: 0;
    }
}

.color-text {
    color: #de4200;
}







.section-navarasa .image-ratio {
    position: relative;
    overflow: hidden;
}

.section-navarasa .image-ratio:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.section-navarasa .image-ratio .ratio-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.section-navarasa .image-ratio .ratio-inner img {
    width: 100%;
    min-height: 100%;
}

.section-navarasa .page-title {
    color: #9a3299;
    margin: 0 0 5px;
}

.section-navarasa .page-description {
    font-size: 16px;
    color: #777;
    margin: 0 0 20px;
}

.section-navarasa .booknavarsa-card {
    width: 300px;
    margin: 0 10px;
    display: inline-block;
    margin-bottom: 30px;
    vertical-align: top;
    position: relative;
    z-index: 0;
    cursor: pointer;
}

.section-navarasa .booknavarsa-card .card {
    position: relative;
    text-align: left;
    height: 300px;
}

.section-navarasa .card-more {
    background-color: #3E434C;
}

.section-navarasa .booknavarsa-card .card .card-img {
    text-align: center;
    background-color: #000;
}

.section-navarasa .booknavarsa-card .card .card-img img {
    transition: transform 0.7s;
    opacity: 0.8;
    height: auto;
    object-fit: contain;
}

.section-navarasa .booknavarsa-card .card:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 70px;
    display: block;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#000000", GradientType=0);
    /* IE6-9 */
}

.section-navarasa .booknavarsa-card .card .card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    overflow: hidden;
}

.section-navarasa .booknavarsa-card .card .card-content .content {
    transition: transform 0.4s;
    transform: translateY(100%);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#000000", GradientType=0);
    /* IE6-9 */
}

.section-navarasa .booknavarsa-card .card .card-content .title {
    color: #fff;
    font-weight: 600;
    font-size: 24px;
    transform: translateY(-100%);
    transition: transform 0.4s;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 15px 15px;
}

.section-navarasa .booknavarsa-card .card .card-content .description {
    padding: 0 15px;
    color: #fff;
    font-size: 13px;
    margin: 0;
}

.section-navarasa .booknavarsa-card .card:hover .card-content .content {
    padding-bottom: 15px;
    transform: translateY(0);
}

.section-navarasa .booknavarsa-card .card:hover .card-content .title {
    transform: translateY(0);
}

.section-navarasa .card {
    overflow: hidden;
    border-radius: unset;
}

.card-content.content .title {
    color: #fff;
    font-size: 22px;
    font-family: 'Poppins-Medium';
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15em;
    /* line-height: 1.5; */
    text-align: left;
    margin-top: 12px;
    text-transform: capitalize;
}

.read-more {
    color: #fff;
    font-family: 'Poppins-Medium';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 17px;
    cursor: pointer;
}

.footer-wrapper {
    background-color: #282828;
}

.texthead-star-writer {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    font-family: 'Poppins-Medium';
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15em;
    line-height: 1.5;
}

.followers-text {
    font-size: 16px;
    font-family: 'Poppins-Medium';
    /* font-weight: 600; */
}

.zoom-effect-container {
    position: relative;
    /* margin: 0 auto; */
    overflow: hidden;
}


.image-card img {
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 50%;

}

/* .image-card{
    box-shadow: 0 30px 60px 0 rgb(222 66 0);
} */

.zoom-effect-container:hover .image-card img {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}

.footer-list {
    padding-left: 0;
    display: flex;
    margin: 0px;color: #fff;
}

.footer-list li {
    list-style-type: none;
    padding: 0 8px;cursor: pointer;
}

.footer-list li a {
    color: #fff;
    font-family: 'Poppins-Medium';
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.social-icons li {
    color: #fff;
    margin: 6px;
}

.social-icons li svg:hover {
    color: #de4200;
}

.social-icons li svg {
    color: #fff;
    font-size: 26px;
}

.social-icons {
    padding-left: 0;
    list-style-type: none;
}

.social-icons {
    display: flex;
}

.footer-outer {
    align-items: center;
    padding: 50px 0;
}

.footer-text-follow {
    color: #de4200;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 14px;
    font-family: 'Poppins-SemiBold';
}

.sub-footer {
    background-color: #000;
}

.sub-footer-text {
    color: #9f9d9c;
    font-size: 14px;
    padding: 20px 0;
    text-align: center;
}


.popup {
    display: none;
    position: fixed;
    padding: 10px;
    width: 500px;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* margin-top: -100px; */
    background: #de4200;
    z-index: 20;
    text-align: center;
}

#loginPopup,
#followersId,
#statusId ,#supportPopup{
    -webkit-box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0px 0px 9999px rgba(0, 0, 0, 0.7);
    z-index: 99;
}

.login-wrapper .inputbox {
    position: relative;
    margin: 38px 0;
    /* margin: 20px 0; */
    /* max-width: 310px; */
    border-bottom: 2px solid #fff;
    /* border-bottom: 1px solid #3c3b3b; */
}

.login-wrapper .inputbox label {
    position: absolute;
    top: 70%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1rem;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
    font-family: 'Poppins-Medium';
    font-size: 16px;
}

.login-wrapper input:focus~label,
.login-wrapper input:valid~label {
    /* top: -5px; */
    top: -12px;
}

.login-wrapper .inputbox input {
    width: 100%;
    height: 45px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0 35px 0 5px;
    color: #fff;
}

.login-wrapper .inputbox .email-input {
    /* font-family: 'Poppins-Medium'; */
    /* font-size: 16px; */

}

.login-wrapper .inputbox svg {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 16px;
    top: 20px;
    opacity: 0.9;
}

.login-wrapper .forget {
    margin: 15px 0;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: end;
    font-family: 'Poppins-Medium';

}

.login-wrapper .forget label {
    display: flex;
    align-items: center;
}

.login-wrapper .forget label input {
    margin-right: 3px;
}

.login-wrapper .forget a {
    color: #fff;
    text-decoration: none;
    /* font-weight: 600; */
    cursor: pointer;
}

.login-wrapper .forget a:hover {
    text-decoration: underline;
}

.login-wrapper button {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background-color: rgb(255, 255, 255, 1);
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    /* font-weight: 600; */
    transition: all 0.4s ease;
    font-family: 'Poppins-Medium';
}

.login-wrapper button:hover {
    background-color: rgb(255, 255, 255, 0.5);
}

.login-wrapper .register {
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin: 25px 0 10px;
    font-family: 'Poppins-Regular';
}

.login-wrapper .register p a {
    text-decoration: none;
    color: #fff;
    font-family: 'Poppins-Medium';
    cursor: pointer;
}

.login-wrapper .register p a:hover {
    text-decoration: underline;
}

.login-wrapper {
    padding: 20px 40px;
}

.login-headtext {
    font-size: 23px;
    font-family: 'Poppins-Medium';
    /* font-weight: 600; */
    color: #fff;
    margin-bottom: 0px;
}

.underline-login {
    background-image: url("../images/underline-white.png");
    background-repeat: no-repeat;
    float: left;
    height: 13px;
    margin: 4px 0 5px;
    width: 100%;
    background-size: contain;
    object-fit: contain;
    /* opacity: 0.5; */
}

.underline-login.center {
    background-position: center top;
}

.login-wrapper .login-google-btn {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    font-family: 'Poppins-Medium';
    position: relative;
}

.login-wrapper .login-google-btn:hover {
    background-color: rgb(255, 255, 255, 0.5);
    color: #000;
}

.google-img {
    width: 22px;
    position: absolute;
    left: 15px;
}

.booklist-sec,
.booklist-content,
.episode-content-sec {
    cursor: pointer;
}

.booklist-sec img {
    /* width: 100%;
    height: 285px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.4s ease; */
    width: 98%;
    height: auto;
    object-fit: contain;
    cursor: pointer;
    transition: 0.4s ease;
    display: flex;
    margin: 0 auto;
}

.zoom-effect-container:hover .booklist-sec img {
    transform: scale(1.09);
}

.image-overlay {
    background: rgba(0, 0, 0, .7);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    opacity: 0;
    color: #fff;
    font-size: 40px;
    transition: .5s;
}

.booklist-sec {
    position: relative;
    margin: 12px 0;
    transition: .5s;
    height: 375px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff3b;
}

.books-list-wrapper,
.book-detail-wrapper,
.episodeList-wrapper,
.star-writer-detailWrpr {
    background: #171819;
    padding-top: 160px;
    padding-bottom: 100px;
}

.booklist-sec:hover .image-overlay {
    opacity: 1;
}

/* .section-booklist .header-home,
.section-bookdetail .header-home,
.section-star-writerdetails .header-home,
.reg-section .header-home {
    position: fixed;
} */

.section-booklist .episode-partText {
    bottom: 0;
    width: 100%;
    right: 0;
    border-radius: unset;
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    top: unset;
}

.section-booklist .rating-div {
    top: 0;
    border-radius: 3px;
    right: 0;
}

.booklist-content {
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
    opacity: 0.9;
}

.booklist-content h3 {
    font-size: 24px;
    font-family: 'Poppins-Medium';
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15em;
    /* line-height: 1.5; */
    margin-bottom: 8px;
    text-align: left;

}

.booklist-content .authortext {
    font-size: 12px;
    font-family: 'Poppins-Medium';
    display: flex;
    align-items: center;
    justify-content: center;
}

.booklist-content .authortext svg {
    margin-right: 6px;
    opacity: 0.7;
    font-size: 15px;

}

.booklist-searchSec .form-control {
    border: 1px solid #403c3c;
    background: #171819;
    color: #fff;
    font-family: 'Poppins-Medium';
}

.booklist-searchSec .form-control::placeholder {
    font-size: 14px;
    opacity: 0.8;
}

.authortext {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authortext svg {
    font-size: 14px;
    margin-right: 5px;
}

.form-control::placeholder {
    /* color: #de4200; */
    font-family: 'Poppins-Medium';
    font-size: 18px;
    color: #fff;
}

.form-control:focus {
    color: #fff;
    background: #171819;
}

.tabContent .form-control:focus {
    color: #000;
    background: transparent;
}

.tabContent .form-control::placeholder {
    color: #666;
}

select.form-control,
select.form-control:focus {
    /* color: #de4200; */
    font-family: 'Poppins-Medium';
}

.booklist-searchSec {
    background-color: #000;
    padding: 25px;
    width: 97%;
    margin: 0 auto 30px auto;
}

.btn-search {
    border: 1px solid #de4200;
    background: #de4200;
    color: #fff;
    padding: 5px 15px;
    border-radius: 8px;
    font-family: 'Poppins-Medium';
    font-size: 18px;
}

.btn-clear {
    border: 1px solid #de4200;
    background: transparent;
    color: #fff;
    margin-left: 5px;
    padding: 5px 15px;
    border-radius: 8px;
    font-family: 'Poppins-Medium';
    font-size: 18px;
}

.booklist-mainhead h3 {
    color: #de4200;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Poppins-Medium';
    font-weight: 600;
    padding: 30px 0 10px;
}



.btn-boolist-viewmore {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #de4200;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 3px;
    width: 200px;
    text-align: center;
    font-family: 'Poppins-Medium';
    font-weight: 600;
    cursor: pointer;
}

.btn-boolist-viewmore:hover {
    background: #de4200;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #de4200,
        0 0 25px #de4200,
        0 0 50px #de4200,
        0 0 100px #de4200;
}

.btn-boolist-viewmore span {
    position: absolute;
    display: block;
}

.btn-boolist-viewmore span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #de4200);
}

.btn-boolist-viewmore span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #de4200);
}

.btn-boolist-viewmore span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #de4200);
}


.btn-boolist-viewmore span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #de4200);
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
}

/* USING KEYFRAMES */

.btn-boolist-viewmore span:nth-child(1) {
    animation: btn-anim1 1s linear infinite;
}

.btn-boolist-viewmore span:nth-child(2) {
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
}

.btn-boolist-viewmore span:nth-child(3) {
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
}

.btn-boolist-viewmore span:nth-child(4) {
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
}

.btn-viewmore-outer {
    text-align: right;
}

.books-list-wrapper a {
    text-decoration: unset;
}

.bookdetail-outer {
    background-color: #000;
    border-radius: 10px;
}

.bookdetailImg {
    padding: 20px;
}

.bookdetailImg img {
    border-radius: 10px;
}

.book-detail-content {
    padding-top: 20px;
    padding-right: 25px;
}

.book-detail-content h3 {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 40px;

}

.book-detail-content .bookdetail-para {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5;
    padding-top: 15px;
}

.bookdetail-author {
    padding-top: 10px;
    color: #fff;
    opacity: 0.8;
    font-family: 'Poppins-Medium';
    font-size: 14px;
}

.bookdetail-author svg {
    margin-right: 10px;
}

.bookdetail-author .vLine {
    color: #fff;
}

.v-line {
    margin-top: 0.75rem;
    height: 2rem;
    width: 1px;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(97 97 97 / var(--tw-bg-opacity));
    opacity: 0.2;
}

.bookdetail-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-top: 35px;
    padding-bottom: 30px;
    padding-right: 25px;width: 100%;
}

.bookdetail-bottom svg {
    margin-right: 10px;
}

.bookdetail-bottom {
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    opacity: 0.8;
    padding-top: 25px;
    /* position: absolute;
    bottom: 0;
    width: 97%; */
}
.bookdetail-outer .col-lg-8.col-md-8{
position: relative;
}

.m-left {
    margin-left: 10px;
}

.episodeList-wrapper h3 {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 30px;
    /* text-transform: uppercase; */
}

.episodeList-wrapper {
    padding-top: 40px;
}

.episode-content-sec h3 {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5;
    width: 20rem;cursor: pointer;
}

.episode-timetext {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    opacity: 0.7;
}

.eposde-list-activites {
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    opacity: 0.7;
}

.eposde-list-activites svg {
    margin-right: 8px;
}

.episode-content-sec {
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    margin: 10px;
    min-height: 133px;
}

.marquee-content a {
    color: #000;
    text-decoration: unset;
}










/* .star-writer-detailWrpr  .header-text {
    background-color: rgba(176, 176, 176, 0.7);
  } */

.star-writer-detailWrpr .the-header {
    position: relative;
    padding: 100px 20px;
}

.star-writer-detailWrpr .header-background {
    position: absolute;
    width: 100%;
    object-fit: cover;
    /* z-index: -100; */
    bottom: 0;
    left: 0;
    right: 0;
    height: 210px;
    border-radius: 15px;
    opacity: 0.7;
}

.star-writer-detailWrpr .profile-picture {
    border-radius: 50%;
    height: 150px;
    width: 150px;
    /* margin-top: -75px;
    position: absolute;
    top: 50%;
    left: 50%; */
    transform: translateY(-50%);
    object-fit: cover;
    position: absolute;
    bottom: -142px;
    border: 2px solid #d1d1d1;
}

.star-writer-detailWrpr .has-text-centered {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center
}

.star-writer-headText {
    margin-top: 80px;
    text-align: center;
    color: #fff;
    position: relative;
}

.star-writer-headText h3 {
    color: #fff;
    font-size: 28px;
    font-family: 'Poppins-Medium';
    margin-bottom: 5px;
}

.bio-para {
    font-size: 15px;
    margin-top: 5px;
    opacity: 0.9;
}

.author-follow-div {
    width: 170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
    font-family: 'Poppins-Medium';
}

.about-wrapper p {
    color: #fff;
    font-size: 18px;
    font-family: 'Poppins-Medium';
}

.about-wrapper h3 {
    color: #fff;
    font-size: 20px;
    font-family: 'Poppins-Medium';
    margin-top: 25px;
}

.follow-btn-div {
    position: absolute;
    top: -55px;
    right: 0;
}

.follow-btn {
    color: #ff5900;
    font-size: 16px;
    font-family: 'Poppins-Medium';
    border: none;
    padding: 3px 45px;
    /* font-weight: 600; */
    border-radius: 4px;
    background: transparent;
    border: 1px solid #de4200;
    /* letter-spacing: 0.6px; */
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
}

.unfolow-btn {
    color: #fff;
    font-size: 16px;
    font-family: 'Poppins-Medium';
    border: none;
    padding: 3px 45px;
    font-weight: 600;
    border-radius: 4px;
    background: #de4200;
    ;
    border: 1px solid #de4200;
    letter-spacing: 0.6px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
}

.follow-btn svg,
.unfolow-btn svg {
    margin-right: 5px;
}

.follow-btn:hover {
    background-color: #de4200;
    color: #fff;
}

.unfolow-btn:hover {
    background-color: transparent;
    color: #de4200;
}

.color-text-follow {
    color: #ff5900;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 2px;
    text-align: center;
}

.start-writerBookList .react-tabs__tab-list {
    border-bottom: 1px solid transparent;
    margin: 0 0 10px;
    padding: 0;
    width: 400px;
    margin: 25px auto;
}

.start-writerBookList .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    width: 50%;
    text-align: center;
    color: #ff5900;
    font-family: 'Poppins-Medium';
    font-size: 20px;
    /* transition: all 0.3s ease-in-out; */
}

.start-writerBookList .react-tabs__tab--selected {
    background: transparent;
    border-bottom: 2px solid #ff5900;
}

.start-writerBookList .react-tabs__tab:focus:after {
    background: transparent;
    /* height: 2px; */
}



.section-star-writerdetails .react-tabs__tab-list {
    border-bottom: 1px solid transparent;
    margin: 0 0 10px;
    padding: 0;
    width: 450px;
    margin: 25px auto;text-align: center;
}

.section-star-writerdetails .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    width: 25%;
    text-align: center;
    color: #ff5900;
    font-family: 'Poppins-Medium';
    font-size: 20px;
    /* transition: all 0.3s ease-in-out; */
}

.section-star-writerdetails .react-tabs__tab--selected {
    background: transparent;
    border-bottom: 2px solid #ff5900;
}

.section-star-writerdetails .react-tabs__tab:focus:after {
    background: transparent;
    /* height: 2px; */
}



.section-star-writerdetails a {
    text-decoration: none;
    color: #000;
    /* font-weight: bold; */
}

.section-star-writerdetails a.login-btn {
    color: #de4200;
}

.status-wrapper {
    color: #fff;
    font-family: 'Poppins-Medium';
    padding-top: 20px;
}

.status-section-content {
    background-color: #000;
    padding: 20px 20px;
    margin: 0px 0 20px;
    border-radius: 20px;
    box-shadow: 0 2px 0px 0px #ff590038;
    display: inline-grid;
    width: 100%;
}

/* .status-section-content p { */
.status-section-content h3 {
    font-size: 20px;
    font-weight: 600;
    opacity: 0.9;
}

.status-section-content p {
    opacity: 0.9;
    margin: 0px;
}

.followersPopup.popup {
    background-color: #000;
}

.followers-wrapper ul li {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    color: #fff;
    font-family: 'Poppins-Medium';
    align-items: center;
    text-align: left;
    margin-bottom: 10px;
}

.followers-name {
    font-size: 16px;
}

.followers-sub {
    font-size: 14px;
    opacity: 0.7;
}

.followers-btn {
    background-color: #de4200;
    color: #fff;
    font-size: 15px;
    padding: 3px 20px;
    border: unset;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.followers-btn svg {
    margin-right: 5px;
}

.followers-wrapper ul {
    padding-right: 30px;
}

.followers-wrapper .followers-ul {
    height: 400px;
    overflow: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.lang-wraper-scroll {
    overflow: auto;
}

.followers-wrapper ::-webkit-scrollbar,
.readMoreDescription::-webkit-scrollbar,
.lang-wraper-scroll::-webkit-scrollbar,
.navarasa-temrscondtns .modal__content::-webkit-scrollbar,
.detail-decsrptn::-webkit-scrollbar,
.search-autocomplete ul::-webkit-scrollbar ,
.newPostScrollauto::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.followers-wrapper ::-webkit-scrollbar-track,
.lang-wraper-scroll::-webkit-scrollbar-track,
.navarasa-temrscondtns .modal__content::-webkit-scrollbar-track,
.detail-decsrptn::-webkit-scrollbar-track,
.search-autocomplete ul::-webkit-scrollbar-track,
.readMoreDescription::-webkit-scrollbar-track {
    background: #403c3c;
}

/* Handle */
.followers-wrapper ::-webkit-scrollbar-thumb,
.lang-wraper-scroll::-webkit-scrollbar-thumb,
.navarasa-temrscondtns .modal__content::-webkit-scrollbar-thumb,
.detail-decsrptn::-webkit-scrollbar-thumb,
.search-autocomplete ul::-webkit-scrollbar-thumb,
.readMoreDescription::-webkit-scrollbar-thumb {
    background: #de4200;
}


/* Handle on hover */
.followers-wrapper ::-webkit-scrollbar-thumb:hover,
.lang-wraper-scroll::-webkit-scrollbar-thumb:hover,
.navarasa-temrscondtns .modal__content::-webkit-scrollbar-thumb:hover,
.detail-decsrptn::-webkit-scrollbar-thumb:hover,
.search-autocomplete ul::-webkit-scrollbar-thumb:hover,
.readMoreDescription::-webkit-scrollbar-thumb:hover {
    background: #de4200;
}

.newPostScrollauto::-webkit-scrollbar-track {
    background: #ffffff17;
}
.newPostScrollauto::-webkit-scrollbar-thumb {
    background: #ffffff52;
}
.newPostScrollauto::-webkit-scrollbar-thumb:hover{
    background: #ccc;
}
.close-popup {
    color: #de4200;
    text-align: right;
    margin: 5px;
    font-size: 16px;
    font-family: 'Poppins-Medium';
}

.google-payImg {
    width: 130px;
    height: auto;
}

.footer-right-sectionIcon {
    padding-left: 10px;
    margin-bottom: 25px;
    align-items: baseline;
}

.footer-list li a:hover {
    color: #de4200 !important;
}

.application-left {
    margin-left: 100px;
}

.footer-rightSec .category-name {
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    /* opacity: 0.9; */
}

.footer-rightSec .category-name:hover {
    color: #ff5900;
}

.viewmoreLink {
    color: #ff5900;
    cursor: pointer;
    text-align: center;
    padding-top: 10px;
    font-size: 15px;
    padding-right: 70px;
    font-family: 'Poppins-Medium';
}

.reviewsWrapper .card .comment_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.reply-btn {
    background-color: #de4200;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 2px 10px;
    font-family: 'Poppins-Medium';    border: 1px solid #de4200;
}

.cancel-btn-reply {
    border: 1px solid #de4200;
    background-color: transparent;
    color: #dd5f1c;
    font-weight: 700;
    font-family: 'Poppins-Medium';
}

.sub-commentSection .comment .comment_content p {
    font-size: 16px;
    margin-bottom: 6px;
}

.review-wrapper input:checked+label {
    background: #de4200;
}

.review-wrapper label:after {
    top: 2px;
    width: 14px;
    height: 14px;
}

.review-wrapper label {
    height: 18px;
    width: 38px;
}

.rating-wrpr svg {
    width: 25px;
    height: 25px;
}

.rating-text {
    font-size: 16px;
    font-family: 'Poppins-Medium';
    font-weight: 600;
    color: #ff5900;
    letter-spacing: 3;
    opacity: 0.9;
    padding-left: 10px;
}

.rating-wrpr {
    display: flex;
    align-items: center;
}

.toprating-wrpr label {
    height: 18px;
    width: 38px;
}

.toprating-wrpr svg {
    width: 25px;
    height: 25px;
}

.toprating-wrpr .rating-text {
    font-size: 16px;
    font-family: 'Poppins-Medium';
    font-weight: 600;
    color: #ff5900;
    letter-spacing: 3;
    opacity: 0.9;
    padding-left: 10px;
}

.toprating-wrpr {
    display: flex;
    align-items: center;
    /* margin-left: 30px; */
}

.rating-topText {
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    padding-left: 6px;
    margin: 10px 0;
}

.reviewrating-wrpr svg {
    width: 18px;
    height: 25px;
}

.reviewrating-wrpr {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.review-wrapper .card h1 {
    font-weight: 500;
}

.review-wrapper .card .comment_footer span.replybtn {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    font-weight: 500;
    opacity: 0.8;
}

.review-wrapper .card .comment_footer span.replybtn:hover {
    color: #ff5900;
}

.review-wrapper .card .comment_footer span {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 12px;
    font-weight: 500;
    opacity: 0.8;
}

.reviewsWrapper .reply-inputSec .form-control::placeholder {
    font-size: 14px;
    opacity: 0.7;
}

.review-wrapper .comment_content p {
    margin-bottom: 10px;
}

.comment-headerIcon svg {
    font-size: 36px;
    color: #de4200;
}

.review-popup-wrapper {
    position: absolute;
    top: 100px;
    background: #3E434C;
    /* height: 200px; */
    width: 400px;
    z-index: 9;
    right: 0;
    padding: 15px;
    border-radius: 10px;
}

.review-textarea {
    border: 1px solid #de4200;
    height: 150px;
    overflow: auto;
    width: 100%;
    background-color: #3E434C;
    border-radius: 10px;
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 14px;
    padding: 15px;
}

.review-textarea:focus {
    outline: none;
}

.review-textarea::placeholder {
    color: #fff;
    padding: 0px;
    opacity: 0.7;
    font-family: 'Poppins-Medium';
}

.review-popup-wrapper .comment-toggle-text {
    font-family: 'Poppins-Medium';
    color: #fff;
}

.btn-tag {
    background-color: #403c3c;
    font-size: 14px;
    padding: 4px 10px;
    border-radius: 2px;
    color: #de4200;
    font-family: 'Poppins-Medium';
    font-weight: 600;
    /* box-shadow: 10px 10px 10px -1px rgba(10,99,169,0.16),
                -10px -10px 10px -1px rgba(255, 255, 255, 0.70); */
}

.comment-tags {
    margin-left: 52px;
}

.epiosde-detail-wrapper .bkBg {
    background-color: #000;
    border-radius: unset;
    box-shadow: unset;
    color: #fff;
    font-family: 'Poppins-Medium';
    border-radius: 10px;
    padding: 30px;
}

.epiosde-detail-wrapper .main-bookHead {
    text-transform: capitalize;
    font-size: 35px;
    margin-bottom: 0;
}

.book-description {
    font-size: 18px;
    text-align: justify;
    user-select: none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
}
.section-bookdetail .main-bookHead{
    user-select: none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;  
}

.reg-wrapper .inputbox {
    width: 45%;
    margin: 15px auto;
    border-bottom: 1px solid #fff;
    padding: 0px;
}

.reg-wrapper .bannerImg::before {
    background: rgba(0, 0, 0, 0.9);
}

.reg-wrapper .inputbox svg {
    color: #de4200;
}

.reg-wrapper.login-wrapper button {
    background-color: #de4200;
    width: 25%;
    color: #fff;
}

.reg-wrapper.login-wrapper button:hover {
    background-color: #ff7236;
}

.reg-wrapper select.genderSelect {
    background-color: transparent;
    padding: 20px 3px 0;
    font-size: 16px;
    height: 45px;
}

.reg-wrapper .register a {
    color: #ff5900;
}

.reg-wrapper.login-wrapper .register p a {
    color: #ff5900;
}

.reg-wrapper.login-wrapper .register p a:hover {
    text-decoration: none;
}

.reg-wrapper.login-wrapper .react-datepicker-popper button {
    background-color: transparent;
}

.signupFields {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reg-section .bannerContent {
    top: 57%;
}

.reg-wrapper .select.genderSelect option {
    background-color: #000;
}

.reg-wrapper .datePickerWrap .react-datepicker {
    background-color: #d1d1d1;
}


.back-to-top {
    background-color: #de4200;
    text-decoration: none;
    cursor: pointer;
    width: 56px;
    height: 56px;
    z-index: 999;
    position: fixed;
    right: 32px;
    bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.4);
}

.back-to-top:hover {
    background-color: #dd5f1c;
}

.back-to-top:active {
    box-shadow: none;
    background-color: #dd5f1c;
}

.back-to-top svg {
    fill: white;
    width: 20px;
    height: 20px;
}




.header-right-sec {
    text-align: right;
}



.login-btn {
    background: transparent;
    border: unset;
    font-family: 'Poppins-Medium';
    color: #de4200;
    font-size: 16px;
    position: relative;
    padding: 15px;
    margin-top: 15px;
    /* font-weight: 700; */
    text-decoration: unset;
}

.login-btn::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 30px;
    background-color: #fff;
    opacity: 0.5;

}

.login-btn:last-child::after {
    position: unset;
}

.writehere-btn {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 110px;
    height: 110px;
    z-index: 10;
}

.writehere-btn:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 93%;
    height: 93%;
    border: 2px solid #de4200;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    animation: rotateme 15s infinite linear;
}

@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.writehere-btn .theme-btn {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    color: #000;
    color: var(--blackcolor);
    background: #de4200;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    z-index: 1;
    cursor: pointer;
}

.write-text {
    font-size: 15px;
    padding-top: 15px;
    color: #fff;
    opacity: 0.9;
    font-family: 'Poppins-Medium';
}






.tag-comment {
    display: inline-block;
    width: auto;
    height: 24px;
    background-color: #de4200;
    border-radius: 3px 4px 4px 3px;
    border-left: 1px solid #de4200;
    margin-left: 19px;
    position: relative;
    color: white;
    font-weight: 300;
    font-family: 'Poppins-Medium';
    font-size: 13px;
    padding: 2px 10px 0 10px;
}

/* Makes the triangle */
.tag-comment:before {
    content: "";
    position: absolute;
    display: block;
    left: -12px;
    top: 0;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #de4200;
}

.or-text {
    font-size: 18px;
    color: #fff;
    font-family: 'Poppins-Medium';
}

.dobPicker {
    cursor: pointer;
}

/* Makes the circle */
/* .tag-comment:after {
	content: "";
	background-color: white;
	border-radius: 50%;
	width: 4px;
	height: 4px;
	display: block;
	position: absolute;
	left: -9px;
	top: 17px;
} */
.navarasa-temrscondtns .modal__content h3 {
    background-color: #000;
    color: #fff;
    font-family: 'Poppins-Medium';
    text-transform: uppercase;
    letter-spacing: 2;
    font-size: 18px;
}

.navarasa-temrscondtns .modal__content {
    max-width: 90%;
    background: #000;
    border: 1px solid #000;
    color: #fff;
    font-family: 'Poppins-Medium';
    height: 550px;
    overflow: auto;
    padding: 20px;
    font-size: 14px;
}

.acpt-btn {
    background: #de4200;
    color: #fff;
    font-size: 13px;
    padding: 6px 10px;
    border: none;
}

.logoimg {
     cursor: pointer;
    padding: 15px 0 0;
    width: 80%;
}

.detail-readmore { 
    color: #ff5900;
    font-size: 15px;
    font-family: 'Poppins-Medium';
    font-weight: 500;
    padding-top: 20px;
    text-align: right;
    cursor: pointer;
    border: 1px solid #ff5900;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 30px;
    border-radius: 4px;
    background: transparent;
    min-width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    float: right;
}
.detail-readmore.readEpi {
    color: #ff5900;border: none;
    font-size: 15px;
    font-family: 'Poppins-Medium';
    font-weight: 500;
    padding-top: 20px;
    text-align: right;
    cursor: pointer; 
     padding: 5px 0px;
    margin-top: 15px;
}

.write-here-sec {
    background-color: #000;
}

.write-here-wrapper .form-control {
    border: 1px solid #403c3c;
    background: #171819;
    color: #fff;
    font-family: 'Poppins-Medium';
    padding: 10px;
}

.write-here-wrapper .form-control::placeholder {
    font-size: 15px;
    font-family: 'Poppins-Regular';
    opacity: 0.7;
}

.write-here-wrapper .form-group {
    margin-bottom: 30px;

}

.write-here-wrapper {
    padding: 150px 30px 100px;
}

.img-upload input {
    display: none;
}

.img-upload {
    font-size: 16px;
    padding: 7px 15px 7px;
    text-transform: uppercase;
    color: #fff;
    background-color: #ff7236;
    text-align: center;
    border: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.img-upload:hover {
    background-color: #de4200;
}

.img-upload:active {
    top: 2px;
    box-shadow: none;
}

.write-here-wrapper label {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 15px;
    margin-bottom: 10px;
}

.write-here-wrapper .rdw-editor-main {
    border: 1px solid #403c3c;
    color: #fff;
    opacity: 1;
    font-family: 'Poppins-Medium';
    padding: 0 15px;
    border-radius: 4px;
}

.write-here-wrapper .css-13cymwt-control {
    background: #000;
    color: #fff;
    padding: 2px;
}

.main-head-chpters {
    font-size: 24px;
    color: #fff;
    font-family: 'Poppins-Medium';
    padding: 20px 0;
}

.booklist-content-top {
    text-align: center;
    color: #fff;
    opacity: 0.9;
    padding: 10px 5px;
    position: relative;
    z-index: 99;

}

.booklist-content-top h3 {
    font-size: 16px;
    font-family: 'Poppins-Medium';
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15em;
}

.continue-google-reg {
    display: flex;
    justify-content: center;
}

.continue-google-reg .login-google-btn {
    border: unset;
}

.continue-google-reg .login-google-btn:hover {
    color: #fff;
}

.continue-google-reg .google-img {
    width: 19px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.profile-camera {
    z-index: 9;
    position: absolute;
    bottom: -52px;
    right: 44%;
    background-color: #c1c1c1;
    width: 30px;
    height: 30px;
    padding: 2px 6px 5px 5px;
    border-radius: 50%;
}

.profile-camera svg {
    color: #fff;
}

.edit-cover {
    color: #fff;
    position: absolute;
    right: 30px;
    bottom: 10px;
    background: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    padding: 4px 10px;
    border-radius: 7px;
    cursor: pointer;
}

.edit-cover svg {
    font-size: 14px;
    margin-right: 5px;
}

.changepassword-btn {
    color: #de4200;
    font-size: 15px;
    font-family: 'Poppins-Medium';
    padding: 3px 18px;
    border-radius: 4px;
    background: transparent;
    border: 1px solid #de4200;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
}

.user-booklist {
    background: #000;
    border-radius: 7px;
    /* box-shadow: 0 3px 6px #ccc; */
    display: inline-block;
    margin: 10px;
    min-height: 215px;
    padding: 20px;
    position: relative;
    vertical-align: top;
    width: 48%;
    text-align: left;
}

.user-booklist-div {
    display: flex;
}

.user-booklist-img {
    width: 130px;
}

.user-booklist-img img {
    border-radius: 4px;
    cursor: pointer;
    height: 130px;
    object-fit: contain;
    object-fit: cover;
    position: relative;
    width: 130px;
}

.user-booklist-detail {
    padding-left: 20px;
    width: calc(100% - 130px);
    color: #fff;
    font-family: 'Poppins-Medium';
    opacity: 0.8;
}

.user-book-list-headText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 260px;
    line-height: 1.5;
    margin-bottom: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
    font-family: 'Poppins-Medium';

}

.detailsubtext {
    font-size: 14px;
}

.user-booklist-episodesCount {
    background-image: linear-gradient(0deg, #111827, #0000);
    border-radius: 3px;
    bottom: 0;
    font-size: 12px;
    left: 2px;
    padding: 6px 5px;
    width: 98%;

    position: absolute;
    color: #fff;
    font-size: 12px;
    border-radius: 3px;
    /* background-image: linear-gradient(to top, #111827, transparent); */
    margin: 0;
}

.user-booklist-status-btn {
    border-radius: 5px;
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 600;
    margin: 5px 0;
    cursor: pointer;
}

.user-booklist-status-btn.status-draftbtn {
    background-color: #c0c0c0;
    color: #3c434c;
}

.user-booklist-status-btn.status-approvedbtn {
    background-color: #a8f796;
    color: #008033;
}

.user-booklist-status-btn.status-unapprovedbtn {
    background: #ffe0e0;
    color: #ff1616;
}

.user-booklist .tag {
    position: absolute;
    display: inline-block;
    border-radius: 6px;
    clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
    background: #545081;
    padding: 5px 23px;
    margin: 0 8px;
    font-weight: 600;
    font-size: 12px;
    color: var(--color-text);
    transition: clip-path 500ms;
    top: 0;
    right: -7px;
}

.user-booklist .tag:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background: var(--color-back);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 6px 0;
    transition: transform 500ms;
}

.user-booklist .tag:hover {
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 0px);
}

.user-booklist .tag:hover:after {
    transform: translate(-100%, -100%);
}

.user-booklist .approved-tag {
    background-color: #a8f796;
    color: #008033;
}

.user-booklist .draft-tag {
    background-color: #c0c0c0;
    color: #5f6c7c;
}

.user-booklist .unapproved-tag {
    background: #ffe0e0;
    color: #ff1616;
}

.userbooklist-section {
    padding-top: 50px;
}

.detail-subsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-booklist-detail svg {
    font-size: 15px;
    color: "#fff"

}

.create-status {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 20px;
    border-radius: 20px;
    margin: 20px 0;
}

.create-status-text {
    margin-left: 20px;
}

.userProfileInfo span.Addplus-btn svg {
    font-size: 42px;
    cursor: pointer;
    color: #fff;
}

.create-status-text {
    margin-left: 20px;
}

.create-status-text p {
    margin-bottom: 0;
    font-size: 14px;
    opacity: 0.8;
}

.createStatusPopup.popup {
    background-color: #000;
    text-align: left;
    padding: 20px 35px;
    width: 600px;
}

.createStatusPopup .form-group label {
    width: 100%;
    margin-bottom: 10px;
}

.createStatusPopup .form-control {
    border: 1px solid #403c3c;
    background: #171819;
    color: #fff;
    font-family: 'Poppins-Medium';
    padding: 10px;
}

.createStatusPopup .form-group {
    margin-bottom: 20px;
}

.createStatusPopup .form-control::placeholder {
    font-size: 14px;
    opacity: 0.7;
}

.btn-section {
    text-align: left;
}

.addpost-statushead {
    font-size: 20px;
    margin-bottom: 24px;
    text-align: left;
}

.btn-photos svg {
    font-size: 22px;
    margin-right: 6px;
}

.createStatusPopup textarea.form-control {
    height: 150px;
    overflow: auto;
}

.btn-postsec {
    text-align: right;
}

.btn-post {
    background: transparent;
    border: 1px solid #de4200;
    padding: 4px 40px;
    font-size: 15px;
    color: #de4200;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 700;
}

.btn-post:hover {
    background-color: #de4200;
    color: #fff;
    font-weight: 500;
}

.createStatusPopup .form-group .btn-photos {
    width: auto;
    cursor: pointer;
    background: #de4200;
    border: none;
    padding: 4px 45px;
    font-size: 16px;
    color: #fff;
    border-radius: 7px;
}

.status-section-content svg {
    position: absolute;
    right: 7px;
    top: 8px;
    font-size: 14px;
    color: #ff5900;
}

.write-here-sectionform .writehere-btn {
    display: none;
}

.list-books-list img {
    height: 280px;
    object-fit: cover;
}

.books-list-wrapper-ul {
    padding-left: 0;
}

.backTo-btn svg {
    color: #fff;
}




.header-home ::selection {
    background: #212129;
}

.header-home .search-wrapper {
    position: absolute;
    /* transform: translate(-24%, -66%); */
    top: 46%;
    right: 24%;
}

.header-home .search-wrapper.active {
    /* right: 20%;
    top: 70%; */
    right: 23.5%;
    top: 36%;
    /* transform: translate(-4%, -70%); */
}

.header-home .search-wrapper .input-holder {
    height: 45px;
    width: 70px;
    /* overflow: hidden; */
    background: rgba(255, 255, 255, 0);
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.header-home .search-wrapper.active .input-holder {
    width: 450px;
    border-radius: 50px;
    background: #000;
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
}

.header-home .search-wrapper .input-holder .search-input {
    width: 100%;
    /* height: 50px; */
    padding: 0px 70px 0 20px;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-family: "Open Sans", Arial, Verdana;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #FFF;
    transform: translate(0, 60px);
    /* transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570); */
    transition-delay: 0.3s;
}

.header-home .search-wrapper.active .input-holder .search-input {
    opacity: 1;
    transform: translate(15px, 10px);
    color: #fff;
    padding: 2px 50px 2px 15px;
    background: transparent;
    width: 85%;
}

.header-home .search-wrapper .input-holder .search-icon {
    width: 50px;
    /* height: 50px; */
    border: none;
    border-radius: 50%;
    background: transparent;
    padding: 0px;
    outline: none;
    position: relative;
    z-index: 2;
    float: right;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}



.header-home .search-wrapper .input-holder .search-icon span {
    width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    transform: rotate(45deg);
    transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
    /* animation: zoom-in-zoom-out 2s ease-out infinite; */
    transform: none;
}

.header-home .search-wrapper.active .input-holder .search-icon span {
    transform: rotate(275deg);
}

.header-home .search-wrapper .input-holder .search-icon span::before,
.header-home .search-wrapper .input-holder .search-icon span::after {
    position: absolute;
    content: '';
}

.search-wrapper .input-holder .search-icon span::before {
    width: 3px;
    height: 8px;
    left: 6px;
    top: 15px;
    border-radius: 2px;
    /* background: #ff5900; */
    background: #ffe0e0;
}

.header-home .search-wrapper .input-holder .search-icon span::after {
    width: 15px;
    height: 15px;
    left: 0px;
    top: 0px;
    border-radius: 16px;
    /* border: 3px solid #ff5900; */
    border: 3px solid #ffe0e0;
}

.header-home .search-wrapper .close {
    position: absolute;
    z-index: 1;
    top: 24px;
    right: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
    transition-delay: 0.2s;
}

.header-home .search-wrapper.active .close {
    right: -50px;
    transform: rotate(45deg);
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.5s;
}

.header-home .search-wrapper .close::before,
.header-home .search-wrapper .close::after {
    position: absolute;
    content: '';
    background: #FE5F55;
    border-radius: 2px;
}

.header-home .search-wrapper .close::before {
    width: 5px;
    height: 25px;
    left: 10px;
    top: 0px;
}

.header-home .search-wrapper .close::after {
    width: 25px;
    height: 5px;
    left: 0px;
    top: 10px;
}

.search-autocomplete {

    background: #0b0c0c;
    position: absolute;
    top: 46px;
    z-index: 9;
    width: 425px;
    border-radius: 4px;
    left: 13px;

}

.search-autocomplete ul {
    padding: 0;
    margin: 15px 0;
    max-height: 325px;
    overflow: auto;
}

.search-autocomplete ul li {
    list-style-type: none;

    color: #fff;
    opacity: 0.8;
    text-align: left;
    font-family: 'Poppins-Regular';

    cursor: pointer;
    padding: 8px 20px;
    font-size: 15px;
    /* text-transform: capitalize; */
}

.search-autocomplete ul li .bold-searchlst {
    color: #ff5900;
    font-family: 'Poppins-SemiBold';
}

.search-autocomplete ul li:hover {
    background-color: #000;
}





.progress-container {
    box-shadow: 0 4px 5px rgb(0, 0, 0, 0.1)
  }
  
  .progress-container, .progress {
    /* background-color: #bdacac; */
    background-color: #e5977540;
    border-radius: 0px;
    position: relative;
    height: 5px;
    width: 300px;
    margin-top: 14px;
  }
  
  .progress {
    background-color: #de4200;
    width: 0;
    transition: width 0.4s linear;
  }
  
  .percentage {
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 0 4px 5px rgb(0, 0, 0, 0.2);
    color: #fff;
    font-size: 11px;
    padding: 1px 4px;
    position: absolute;
    top: -8px;
    right: -70px;
    transform: translateX(-50%);
    width: 44px;
    text-align: center;
    transition: left 0.4s linear;
  }
  
  /* .percentage::after {
    background-color: #de4200;
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    transform: translateX(-50%) rotate(45deg);
    height: 10px;
    width: 10px;
    z-index: -1;
  } */
  .readMoreDescription{
    padding: 0 20px;
    color: #fff;
    max-height: 400px;
    overflow: auto;
    margin: 32px 0;
  }









/* jenyyyyyyyyyyyyyyyyyyyyyyyyy */

.reviewsWrapper .card h1 {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 30px;
}

.reviewsWrapper .bkBg {
    background: #000;
    padding: 10px;
    box-shadow: 0 0px 6px #242222;
    border-radius: 15px;
}

.reviewsWrapper .card .comment_header h2,
.reviewsWrapper .card .comment_content {
    color: #fff;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    font-weight: unset;
}

.reviewsWrapper .reply-inputSec .form-control {
    background-color: transparent;
    border: 1px solid #1e1e1e;
}

.faTimesRight {
    position: absolute;
    top: -45px;
    right: -35px;
    color: #fff;
}

.genderSelect {
    background: transparent;
    border: none;
    color: #fff;
    padding-left: 5px;
    font-size: 18px;
}

.genderSelect:focus {
    border: none;
    outline: none;
}

select.genderSelect,
select.genderSelect option {
    -webkit-appearance: none;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;

    vertical-align: middle;
    background: var(--themeColor);
}

select.genderSelect option {
    color: #fff;
}

select.genderSelect option:hover {
    background: #1e293be0;
}

.forgotPasswrdWrsp .form-control.genderSelect:focus {
    outline: none;
    background-color: transparent;
    background: transparent;
    border: none;
    box-shadow: none;
}

.headtext {
    font-size: 26px;
    font-family: 'Poppins-Medium';
    font-weight: 600;
    color: #000;
}

.resetPasswrdWrap .login-wrapper .inputbox {
    position: relative;
    margin: 30px 0;
    border-bottom: 2px solid #000;
}

.resetPasswrdWrap .login-wrapper .inputbox label {
    color: #000;
}

.resetPasswrdWrap .login-wrapper .inputbox .email-input {
    /* font-size: 18px; */
    color: #000;
}

.resetPasswrdWrap button {
    background: #ff5900;
}

.resetPasswrdWrap .login-wrapper {
    width: 40%;
    margin: 0 auto;
}

.uActivity {
    color: #dd5f1c;
    text-decoration: underline;
    cursor: pointer;
}

.forgotPasswrdWrsp .fotgotpass-wrapper {
    box-shadow: 0 3px 6px #00000061;
}

.content {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    /* overflow: hidden; */
}

.content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
    opacity: 1;
}

.content-image {
    width: 100%;
}

.content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.content-details h3 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.content-details p {
    color: #fff;
    font-size: 0.8em;
}

.fadeIn-bottom {
    /* top: 80%; */
    top: 50%;
}

.categoryTabList .img-fluid {
    /* max-width: 100%;
    height: auto;
    width: 100%;
     height: auto;
    object-fit: contain;
    object-position: center; */
    max-width: 100%;
    height: auto;
    width: 100%;
    height: 300px;
    /* height: auto; */
    object-fit: contain;
    object-position: center;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #de4200 !important;
    color: #fff;
}

.newsTime {
    width: 100%;
    display: flex;
    justify-content: end;
    color: #999999e0;
    font-weight: bold;
}

.section-star-writerdetails a.newsLink {
    word-break: break-word;
    color: #b33f00;
    cursor: pointer;
}

.masnoryEffect {
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsImg {
    border-radius: 15px;
    margin: 15px 0;
    width: 100%;
    height: 285px;
    object-fit: cover;
}

.card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    /* widows: 1; */
}

.loginImgposition img {
    width: 100%;
}

.backTo-btn a {
    color: #fff;
}

.detail-decsrptn {
    /* color: #fff;
    margin-top: 20px; 
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5; */
    color: #fff;
    margin-top: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5;
    min-height: 120px;
 }

.actionsBtn {
    display: flex;
    justify-content: end;
}

.actionsBtn span {
    display: inline-block;
    min-width: 125px;
    background-color: #de4200;
    margin-left: 10px;
    padding: 5px 20px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    color: #ffffffad;
    border-radius: 4px;

}

.actionsBtn span:hover {
    opacity: .8;
}

.actionsBtn span.btnCanceled {
    background: #282828;
    color: #ffffffad;
}

.revwsubmt-bottm-sec .comment-toggle.commntRply {
    margin-right: 30px;
    color: #fff;
}

.reply-inputSec .form-control,
.reply-inputSec .form-control:focus {
    color: #fff;
}

.write-here-wrapper .css-13cymwt-control {
    border-color: #403c3c !important;
}

.search-local input[type="text"]::placeholder,
#selectFilter::placeholder,
#react-select-2-placeholder,
.write-here-wrapper .css-qbdosj-Input,
.write-here-wrapper .css-qbdosj-Input input {
    color: #ffffff8f !important;
    font-size: 16px;
}

.write-here-wrapper .css-t3ipsp-control:hover,
.write-here-wrapper .css-t3ipsp-control:focus {
    border-color: #403c3c !important;
    box-shadow: none;
    background: transparent;
}

.write-here-wrapper .css-t3ipsp-control {
    background: transparent;
    color: #ffffff8f !important;
}

.write-here-wrapper .css-1bocsem-control:hover,
.write-here-wrapper .css-1bocsem-control:visited,
.write-here-wrapper .css-1bocsem-control {
    border-color: #403c3c !important;
    box-shadow: none;
}

.book-description p {
    word-break: break-word;
}

.addChapBtn {
    background: #de4200;
    color: #fff;
    font-size: 14px;
    padding: 6px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.writeHereModal .modal__content h3 {
    background-color: #000;
    color: #fff;
    border-radius: 6px 6px 0 0;
    margin-top: 20px;
}

.writeHereModal .modal__content {
    border-radius: 6px;
    position: relative;
    width: 80%;
    max-width: 90%;
    background-color: #000;
    border: 0px solid #de4200;
}

.write-here-wrapper.writeinnerSec {
    padding: 15px;
}

.writeHereModal .css-b62m3t-container {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.termsPolicyWrap {
    background: #282828;
    color: #fff;
}

.termsPolicyWrap .header {
    background: #282828;
    border: none;
    box-shadow: none;
}

.termsPolicyWrap .termsHead {
    padding-top: 120px;
}

.bookDuration {
    display: block;
    /* white-space: nowrap; */
    /* overflow: hidden;
    text-overflow: ellipsis; */
    /* width: 60px; */
}

.noDataImg {
    width: 100px;
    margin-bottom: 15px;
}

.noDataTxts span p {
    margin: 0;
    padding: 15px 0;
}

.noDataTxts {
    /* min-height: 400px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.starBooksNodata .noDataTxts {
    min-height: 200px;
}

.bookNameReleased {
    color: #fff;
    text-align: center;
    width: 100%;
    display: block;
    padding-top: 14px;
}

.categoryTxtTitle {
    width: 100%;
    color: #fff;
    padding: 8px;
    background: #282828;
    margin: 0;
    font-size: 16px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.register p {
    margin: 0px;
}

.login-wrapper .inputbox.datePickerWrap input {
    padding-top: 15px;
    padding-left: 20px;
}

.penNameUser {
    font-size: 16px;
}

.penNameUser img {
    width: 18px;
}

.star-red {
    color: #de4200;
}

.write-here-wrapper .rdw-editor-main {
    height: auto;
    min-height: 100px;
    max-height: 160px;
    overflow: auto;
    box-sizing: border-box;
}

.image-card,
.marquee-item {
    cursor: pointer;
}

.writeHereModal.ChangePaswd .modal__content {
    width: 45%;
}

.writeHereModal.ChangePaswd .modal__content .modalFields label {

    min-width: 150px;
}

.writeHereModal.ChangePaswd .modal__content .form-control {
    padding: 7px 10px;
    font-size: 14px;
}

/*  */
.profile-pic {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.profile-pic input {
    display: none;
}

.profile-pic img {
    z-index: 0;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    transform: translateY(-50%);
    object-fit: cover;

    border: 2px solid #d1d1d1;
}

.profile-pic .-label {
    cursor: pointer;
    height: 150px;
    width: 150px;
    position: absolute;
    bottom: 75px;
}

.profile-pic:hover .-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    color: #fafafa;
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
}

.profile-pic span {
    display: inline-flex;
    padding: 5px 0;
    height: auto;
}

.star-writer-detailWrpr .has-text-centered.profilrImg {
    position: absolute;
    width: 98%;
}

.star-writer-detailWrpr .has-text-centered.profilrImg .profile-camera {
    z-index: 9;
    position: absolute;
    bottom: 88px;
    right: 6px;
    background-color: #605d5d;
    width: 30px;
    height: 30px;
    padding: 2px 6px 5px 5px;
    border-radius: 50%;
}

.star-writer-detailWrpr .has-text-centered.profilrImg .profile-camera svg {
    font-size: 15px;
}

.profile-pic1 {
    width: 100%;
    right: 0;
    bottom: 0;
    padding: 5px 10px;
    border-radius: 14px;
}

/*  */
.profile-pic1 {
    color: transparent;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
}

.profile-pic1 input {
    display: none;
}

.profile-pic1 img {
    z-index: 0;
    /* border-radius: 50%;
    height: 150px;
    width: 150px; */
    transform: translateY(-50%);
    object-fit: cover;

    border: 2px solid #d1d1d1;
}

.profile-pic1 .-label {
    cursor: pointer;
    /* height: 150px;
    width: 150px;    */
    position: absolute;
    /* bottom: 75px; */
}

.profile-pic1:hover .-label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    color: #fafafa;
    transition: background-color 0.2s ease-in-out;
    border-radius: 100px;
    margin-bottom: 0;
}

.profile-pic1 span {
    display: inline-flex;
    padding: 5px 0;
    height: auto;
}

.profileInfoEdit {
    width: 50%;
    margin: 0 auto;
}

.follow-btn-div.follow-btn-divProfile {
    display: flex;
}

.EditProfile {
    margin-right: 5px;
}

.userProfileInfo .write-here-wrapper .form-group {
    margin-bottom: 15px;
}

.userProfileInfo .write-here-wrapper .form-group label {
    margin: 0;
}

.upload-cover {
    color: #fff;
    position: absolute;
    right: 145px;
    bottom: 10px;
    background: rgba(0, 0, 0, 0.8);
    font-size: 13px;
    padding: 4px 10px;
    border-radius: 7px;
    cursor: pointer;
}

.upload-cover svg {
    font-size: 15px;
    margin-right: 5px;
}

.profileBkList .bkBg {
    background-color: #000;
    box-shadow: none;
    color: #fff;
    padding: 20px;
}

.myProfileBkDet .card h1 {
    color: #fff;
}

.myProfileBkDet .select-status-sec svg,
.myProfileBkDet .select-status-sec .css-1nmdiq5-menu,
.myProfileBkDet .selectControl-status svg,
.myProfileBkDet .selectControl-status .css-1nmdiq5-menu,
.userProfileInfo .select-language {
    color: #282828;
}

.userProfileInfo .css-1dimb5e-singleValue {
    color: #282828 !important;
}

.profileBkList .episodeBg {
    background-color: #000;
    color: #fff;
    margin: 0 2px 15px;
    padding: 35px 20px 20px
}

.userProfileInfo .profileBkList .booksList-EditWrapper {
    background-color: #000;
    color: #fff;
    text-align: right !important;
}

.userProfileInfo .profileBkList .form-control {
    color: #fff;
    border: 1px solid #403c3c;
    background: #171819;
}

.profileBkList .description-editor .rdw-editor-main {
    padding: 10px;
    border: 1px solid #403c3c;
    background: #171819;
}

.userProfileInfo .profileBkList label.btn-upload.padd-upload {
    padding: 6px 20px;
    background: #000;
    color: #fff;
    border: 1px solid #403c3c;
}

.profileBkList .css-13cymwt-control {
    border: 1px solid #403c3c;
}

.userProfileInfo .profileBkList .css-1xc3v61-indicatorContainer,
.userProfileInfo .profileBkList .css-1fdsijx-ValueContainer {
    background: #000;
}

.userProfileInfo .backToLink svg,
.userProfileInfo span svg {
    color: #282828;
}

.userProfileInfo .css-1dimb5e-singleValue,
.userProfileInfo .select__input-container {
    color: #fff !important;
}

.userProfileInfo .card h1,
.css-qbdosj-Input input {
    color: #282828;
}

.profileBkList .backToLink svg,
.profileBkList span svg,
.profileBkList .css-1dimb5e-singleValue,
.profileBkList .css-13cymwt-control .css-qbdosj-Input {
    color: #fff !important;
}

.profileBkList .css-166bipr-Input {
    color: #fff !important;
}

.profileBkList .css-1fdsijx-ValueContainer {
    padding: 4px 8px;
}

.profileBkList .css-13cymwt-control:hover,
.profileBkList .css-13cymwt-control:focus,
.profileBkList .selectControl-status svg {
    color: #fff;
    background: #000 !important;
}

.profileBkList .css-1hb7zxy-IndicatorsContainer {
    background: #000 !important;
}

.profileBkList .css-t3ipsp-control:hover {
    border-color: #282828;
    box-shadow: none;
}

.profileBkList .tag {
    /* right: 10px; */
    right: -7px;
}

.chapter-outer .tag {
    right: 10px;
}

.profileBkList .modal-content {
    background: #000;
    color: #fff;
}

.profileBkList .btn-saveBook {
    background: #de4200;
    color: #fff;
    border-radius: 4px;
}

.profileBkList .btn-saveBook.btnCancel {
    background: #3e3e3e;
    color: #fff
}

.profileBkList .btn-close,
.profileBkList .card h1 {
    color: #fff;
}

.profileBkList .modal-content label {
    text-align: right;
}

.profileBkList .user-booklist-img img {
    cursor: default;
}

.bookdetail-bottom svg,
.bookdetail-author svg {
    cursor: default;
}

.innerImgs img {
    width: 100%;
}

.divOverlap {
    background: #000000eb;
    position: relative;
    top: -50px;
    padding-top: 50px;
    border-radius: 10px;
    padding: 30px;
    padding-top: 50px;
}

.abtIcon {
    width: 10%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
}

.abtIcon img {
    width: 100%;
}

.divOverlap p {
    font-size: 15px;
    font-weight: normal;
    text-align: justify;
}

.aboutTxt {
    font-size: 30px;
    color: #de4200;
}

.aweContact img {
    width: 100%;
}

.aweAddress p {
    margin: 0;
    text-align: left;
}

.aweAddress {
    display: flex;
    justify-content: left;
    align-items: flex-start;
    margin-bottom: 8px;
}

.aweAddress span svg {
    margin-right: 15px;
    color: #de4200;
}

.enquirySubmit {
    background: #de4200;
    padding: 8px;
    width: 100%;
    display: block;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}

.enquirySubmit:hover {
    background-color: #ff7236;
}

/* =============== */


.services-content .service-box {
    margin-bottom: 30px;
    overflow: hidden;
    position: relative;
    transition: all ease-in 400ms;
    -webkit-transition: all ease-in 400ms;
    -moz-transition: all ease-in 400ms;
}

.services-content.services-carousel .service-box {
    margin-bottom: 10px;
}

.services-content .service-box .img-thumb img {
    /* min-height: 320px; */
    width: 100%;
    object-fit: contain;
    background: #fff;
    border-radius: 50%;
}

.services-content .service-box .content {
    /* bottom: 0; */
    display: inline-block;
    left: 0;
    /* position: absolute; */
    right: 0;
    text-align: center;
    z-index: 1;
    width: 100%;
}

.services-content .content h4 {
    font-size: 20px;
    color: #de4200;
}

.services-content .service-box .overlay {
    background-color: rgb(222 66 0 / 90%);
    display: inline-block;
    left: 0;
    padding: 15px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -100%;
    height: 100%;
    transition: all ease-in 500ms;
    -webkit-transition: all ease-in 500ms;
    -moz-transition: all ease-in 500ms;
    z-index: 0;
}

.services-content .service-box:hover .overlay {
    top: 0;
}

.services-content .service-box .overlay::before {
    background-color: rgb(222 66 0 / 90%);
    bottom: -50px;
    content: "";
    height: 50px;
    left: -25px;
    position: absolute;
    transform: skewX(-40deg);
    -webkit-transform: skewX(-40deg);
    width: 40px;
}

.services-content .service-box .overlay::after {
    background-color: rgb(222 66 0 / 90%);
    bottom: -50px;
    content: "";
    height: 50px;
    right: -25px;
    position: absolute;
    transform: skewX(40deg);
    -webkit-transform: skewX(40deg);
    width: 40px;
}

.services-content .service-box .overlay p {
    color: #fff;
    font-size: 13px;
    line-height: 20px;
}


.services-content .item {
    width: 100%;
}

.services-content {
    width: 100%;

}

.aweDesignation {
    width: 100%;
    display: block;
    text-align: left;
}

.content1 h4 {
    margin: 0;
    color: #de4200;
    font-size: 20px;
}

.content1.text-right,
.aweDesignation.text-right {
    text-align: right;
}

.services-content .row {
    padding: 25px 0;
    border-bottom: 1px solid #dddddd14;
    display: flex;
    align-items: center;
}

.services-content .row:last-child {
    border: none;
}

#comments,
#comments:focus,
#comments:focus-visible {
    margin-top: 13px;
    width: 100%;
    background: transparent;
    border: none;
    resize: none;
    color: #fff;
    outline: none;

    border: 1px solid #fff;
    max-height: 70px;
    padding: 15px;
}

.login-wrapper .inputbox label.commtsLabel {
    top: 0px;
}

.login-wrapper .inputbox svg {
    color: #DE4200;
}

.commentsScroll {
    width: 100%;
    max-height: 120px;
    height: auto;
    padding: 8px;
    position: relative;
    top: auto;
    left: auto
}
.microPhone{
    display: flex;
    align-items: center;
}
.microPhone svg{
    color: #DE4200; 
    margin: 0 5px;
}
.css-1dimb5e-singleValue{
    color: #fff !important;
}
.star-writer-detailWrpr,.book-detail-wrapper{
    min-height: 600px !important;
}
.bookDescrProf .book-description p{
margin: 0px;
}

.header-home .search-wrapper .input-holder .search-icon{
    /* animation: wiggle 2s linear infinite; */
    transform: rotate(45deg);
    overflow: visible;
    /* background: #000; */
    background: #de4200;
    height: 35px;
    width: 35px;
    bottom: 6px;
    padding: 7px 10px;
    /* animation: pulse1 1.5s linear 0s infinite normal; */
    animation: pulse 1.5s linear 0s infinite normal;
}
.header-home .search-wrapper.active .input-holder .search-icon {
    animation: none;
    width: 35px;
    height: 35px;
    /* margin: 2px 10px; */
    border-radius: 50%;
    right: 6px;
    bottom: 0;
    top: 5px;
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Keyframes */
@keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }
  #button  { 
    position: fixed; 
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    /* opacity: 0;
    visibility: hidden; */
    z-index: 1000;
    animation: pulse 1.5s linear 0s infinite normal;
    transition: .2s ease-in-out;border-radius: 50%; 
    background: #de4200;
    padding: 2px 5px;
    left: 30px;
  }
  .header-home .search-wrapper.active#button{
    animation: none;
  }
  #button1{
     transition: background-color .3s, 
      opacity .5s, visibility .5s; 
    animation: pulse 1.5s linear 0s infinite normal;
    transition: .2s ease-in-out;border-radius: 50%; 
    background: #000;
    padding: 2px 5px;
   }
   
   @keyframes pulse1 {
    0% {
        box-shadow: 0 1px 0 3px #000,0 0 0 0 #000
    }

    40% {
        box-shadow: 0 1px 0 3px #000,0 0 0 5px #000
    }

    80% {
        box-shadow: 0 1px 0 3px #282828,0 0 0 15px #282828
    }

    to {
        box-shadow: 1px 1px 2px 1px #000,0 0 0 20px transparent
    }
}   
@keyframes pulse1 {
    0% {
        box-shadow: 0 1px 0 3px #000,0 0 0 0 #000
    }

    40% {
        box-shadow: 0 1px 0 3px #000,0 0 0 5px #000
    }

    80% {
        box-shadow: 0 1px 0 3px #282828,0 0 0 15px #282828
    }

    to {
        box-shadow: 1px 1px 2px 1px #000,0 0 0 20px transparent
    }
}
@keyframes pulse {
    0% {
        box-shadow: 0 1px 0 1px #de4200,0 0 0 0 #de4200
    }

    40% {
        box-shadow: 0 1px 0 1px #de4200,0 0 0 3px #de4200
    }

    80% {
        box-shadow: 0 1px 0 1px #de4200b0,0 0 0 8px #de4200b0
    }

    to {
        box-shadow: 1px 1px 2px 1px #de4200b0,0 0 0 10px transparent
    }
} 

  /* #button:hover {
    cursor: pointer;
    background-color: #333;
  }
  #button:active {
    background-color: #555;
  } */
  #button.show, #button1.show {
    opacity: 1;
    visibility: visible;
  }
  
@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.3, 1.3);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  #buttonArrow {
    display: inline-block; 
    bottom: 30%; 
    position: fixed;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    animation: pulse 1.5s linear 0s infinite normal;
    transition: .2s ease-in-out;
    border-radius: 50%;
    background: #de4200;
    padding: 2px 5px;
    left: 30px;
  }
  
  #buttonArrow.show {
    opacity: 1;
    visibility: visible;
  }
  .libraryIcon{
    width: 24px;
    margin-right: 5px;
  }
  .AweratingWrpr {
    display: flex;
    justify-content: space-between;
    align-items: center;    width: 100%;
  }
 .libraryTxt{
    color: #fff;
 }
 .addTolibraryIcon{
    cursor: pointer;
 }
 .libraryWrap .bookNameReleased {
    color: #000;display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15em;
 }
 .libraryWrap .info-block .texthead{
    font-size: 16px;
 }
 .libraryWrap p{
    margin: 0;
    font-size: 14px;
    text-align: center;
 }
 .section-topbBook.section-latestRelease.libraryWrap ul li { 
    border: 1px solid #272a3040;
 }
 .AwBanner .slick-dots {
    position: absolute;
    bottom: 125px;
 }
 .AwBanner .slick-dots li.slick-active button:before {
    opacity: .75;
    color: #de4200;
}
.AwBanner  .slick-dots li button:before{
    color:#ffffffb3;
}
.slick-dots li.slick-active button{
    border: 1px solid #de4200 !important;
    border-radius: 50%;
}
.newPost{
    position: fixed;
    right: 15px;
    top: 10px;width: 85px;cursor: pointer;z-index: 9;
}
#newPostMdl .modal__content {
    border-radius: 15px;
    position: relative;
    width: 450px;
    max-width: 90%;
     border: none;
    padding: 15px;
    background-image: url('../images/post-avatar.jpg');    background-size: 100% 100%;
}
.postHeadings{
    display: flex;
    justify-content: space-between;
    align-items: center;margin-bottom: 15px;
}
.modal__content h3.addPostCmmn{
    margin: 0px;
    background: transparent;
    color: #fafafa;
    padding: 0;
}
#newPostMdl svg,#newPostMdl .createStatusPopup .form-group label{
    color: #ffffffc4;
}
.postCards h5{
    font-size: 15px;
    text-transform: uppercase;
    border-bottom: 2px solid #ffffff29;
    padding-bottom: 3px;width: 100%;
}.postCards h6{
    font-size: 15px;
}
.postCards p{
    margin: 0;
    font-size: 12px;
    margin-bottom: 8px;
}.postCards p:first-child{
    margin-bottom: 0px;
}
.postCards{
    color: #ffffffc4; 
    background: #ffffff17;
    padding: 15px;
    border-radius: 15px;
    margin-top: 15px;
}.postCards:first-child{
    margin-top: 0px;
}
.postCardsAthr img{
    width: 85%;
    border-radius: 50%;
    margin: 0 auto;
}
.postCardsAthr{
    display: flex;
    align-items: center;
}
.postCardsImg{
    width: 100%;
}
.newPostScrollauto {
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;    padding-right: 5px;
}#newPostMdl .postHeadings{
    margin-bottom: 8px;
}
.postCards.postResults{
    width: 80%;
    margin: 8px auto;
}
.libRemove { 
    position: absolute;
    right: 8px;
    width: auto;
    bottom: 8px;
    color: #fff;
    display: grid;
    align-items: center;
    background-color: #000;
    padding: 4px 5px;
    border-radius: 6px;
    cursor: pointer;
    justify-content: center;
    text-align: center;
}
.libRemove svg{
    font-size: 15px;
}
.lilistPro .position-relative.li{
    background: #000;padding: 5px;height: auto !important;
    margin-bottom: 30px;
}
.lilistPro .position-relative.li p,.lilistPro .position-relative.li span{
    color: #fff;
}
.noDataFound{
     display: inline-flex;
    margin: 10px;
    min-height: 215px;
    padding: 20px;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
    align-items: center;
 }
.noDataFound p{
    margin: 0px;
}
.generalSettings{
    position: absolute;
    background: #171819;
    width: 20%;
    z-index: 1;
    height: 95px;
    top: 220px;
    right: 135px;
    padding: 10px;
    box-shadow: 0px 2px 4px 0px #262525;
}
 
.create-status-text{
    text-align: left;
}
.font-size-changer-buttons{
    cursor: pointer;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.font-size-changer .font-size-changer-buttons > div {
    width: 45px !important;
    border: 1px solid #171819 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.font-size-changer .font-size-changer-buttons > .font-size-up:hover,
.font-size-changer .font-size-changer-buttons > .font-size-down:hover{
    border: 2px solid #de4200 !important;
}
/* =============================================================== */
._lightMode_ .epiosde-detail-wrapper .bkBg,
._lightMode_ .reviewsWrapper .bkBg,
._lightMode_ .reviewsWrapper .card .comment_header h2, 
._lightMode_ .reviewsWrapper .card .comment_content,
._lightMode_ .review-wrapper .card .comment_footer span {
    background-color: #fff;color: #000;
}
._lightMode_ .reviewsWrapper .reply-inputSec .form-control,
._lightMode_ .reply-inputSec .form-control, 
._lightMode_ .reply-inputSec .form-control:focus,
._lightMode_ .revwsubmt-bottm-sec .comment-toggle.commntRply{
color: #000;
}
._lightMode_  .font-size-changer-buttons img{
    filter: invert(1);
}
.awe{
    display: flex;
    align-items: center;
}
._lightMode_  span.font{
    color: #000 !important;
}
.AweCategory ul{
    padding: 8px 0 0;
    margin: 0;
}
.AweCategory ul li{
    background: #171819;
    list-style: none;
    color: #fffc;
    display: inline-flex;
    font-size: 13px;
    border: 1px dashed #171819;
    border-radius: 15px;
    padding: 2px 15px;
    margin-right: 8px;margin-bottom: 8px;
}
.AweCategory ul li:hover{
    background: #242424;
}
.athrImg {
    width: 35px;
    height: 35px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #ffffff30;
}
.detail-readmore.readEpi.readNextEpi{
    float: left;
    padding: 5px 15px;
    border: 1px solid #ff5900;
    background: #ff5900;
    color: #fffffff7;
    opacity: .9;
}
.detail-readmore.readEpi.readNextEpi:hover{
  
    border: 1px solid #ff5900;
    background: transparent;
    color: #ff5900; opacity: 1;
}
.detail-readmore.readNextEpi.bdnextepi{
    float: none;
    margin: 0 auto;padding: 5px 20px;
}
.detail-readmore.readNextEpi.bdnextepi:hover{
    float: none;
    margin: 0 auto;
    border: 1px solid #ff5900;
    background: #ff5900;
    color: #fffffff7;
 }
 ._darkMode_ .revwsubmt-bottm-sec .comment-toggle.commntRply{
    color: #fff;
}
.Addplus-btn.addWrite1{
    cursor: pointer;
}
._darkMode_ .write-here-sec .css-1dimb5e-singleValue{
    color: #fff !important;
}
.userProfileInfo .bookDescrProf .card h1 {
    color: #fff !important;
}
/* jenyyyyyyyyyyyyyyyyyyyyyyyyy */
.libRemove.libRemovePro {
    bottom: 90px;
    right: 7px;
}
/* .aweReview{
    color: #de4200;
    -webkit-transform: rotate(270deg);
    background: #000;
    font-size: 14px;
    padding: 8px 60px;
    display: flex;
    justify-content: center;
    align-items: center;
     cursor: pointer;  
    border: 1px solid #de4200d6;
   
} */
 
.aweReview h3{
    line-height: unset;
    margin: 0;
    font-size: 15px;
    color: #de4200;
     font-family: 'Poppins-Medium';
    letter-spacing: 0.5px;font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 10px 32px;
}
.floating{
    position: fixed;
    right: 21px;
    top: 300px;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    
}
.aweReview  {
    margin-bottom: 0;
    margin-right: -69px;
    position: relative;
    right: 0;
    /* padding: 10px 32px; */
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    background: radial-gradient(circle, #171819 0, #171819 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: width .5s, background-color .1s;
    border: 1px dashed #de42006b;
    border-bottom: none;
    /* animation: pulse1 2s linear 0s infinite normal; */
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 500;
    cursor: pointer;
}
@keyframes pulse1{
    0% {
        box-shadow: 0 0px 0 3px #000, 0 0 0 0 #de4200;
    }
    
    50% {
        box-shadow: 0 -2px 0px 1px #000, 0 0 0 15px #000;
    }
    100% {
        box-shadow: 0px 3px 0px 0px #000, 0 0 0 3px #000;
    }
}
 @keyframes floating{
    0% {
        transform: translate(0);
    }
    65% {
        transform: translateY(15px);
    }
    100% {
        transform: translate(0);
    }
 }
 
 .card.lang-wraper-scroll.aweReviews{
    max-height: 300px;
    overflow-y: auto;    padding-right: 5px;
 }
 .reviewsWrapper .card.lang-wraper-scroll.aweReviews .comment_header h2, 
 .reviewsWrapper .card.lang-wraper-scroll.aweReviews .comment_content { 
    font-size: 14px;
 }
 .reviewsWrapper .card.lang-wraper-scroll.aweReviews h1{
    font-size: 17px;
 }
 .writeHereModal.ChangePaswd .modal__content .modalFields .card.lang-wraper-scroll.aweReviews label {
    min-width: auto;
 }
 .writeHereModal.ChangePaswd .modal__content .modalFields .review-popup-wrapper label{
    min-width: auto;
 }
 .aweRevw{
    padding: 5px;
 }
 
 .writeHereModal .modal__content.aweRevw  h3{
    margin-top: 0px;
 }
 .reviewsWrapper .aweReviews .bkBg{
    box-shadow: none;
 }
 .aweRevw .modal__close {
     top: 12px;
  }
  .aweQuery textarea{
    width: 100%;
    border: 1px solid #303030b5;
    background: transparent;
    padding: 15px;
    outline: none;
    color: #fff;
    margin: 5px 0px 0px;
    border-radius: 4px;
    resize: none;font-size: 14px;
  }
  .aweQuery textarea::placeholder{
    color: #fff;
  }
  .aweQuery span{
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 25px;
    border-radius: 20px;
    margin-top: 15px;
  }
  .aweSupport .login-wrapper button{
    width: 30%;
     margin-top: 15px;
  }
  .aweSupport .faTimesRight {
    position: absolute;
     top: -70px;
    right: -37px;
 }
 .aweSucessMsg p{
    color: #fff;
    margin: 0px;
 }
 
  
 .Queryinfoo{
    background-color: #000;
    padding: 15px;
    width: 98%;
    margin-bottom: 10px;
    border-radius: 4px;    position: relative;
 }
 .Queryinfoo img{
    width: 100%;
 }
 .AweStatus{
    position: absolute;
    right: 20px;
 }
 .Queryinfoo ol{
    padding-left: 0px;margin-bottom: 0px;
 }
 .Queryinfoo li{
    color: #fffc;
     list-style: none;
    margin-bottom: 3px;
    font-size: 14px;
 }
 .Queryinfoo svg{
    margin-right: 8px;color: darkgrey;
 }
 .aweSupprtCard p.statusGreen{
    color: green ;
 }
 .aweSupprtCard p.statusOrange{
    color: #de4200 ;
 }
 .aweSupprtCard p.statusGrey{
    color: grey;
 }
 .Queryinfoo span{
    color: #fff;
    font-size: 13px;
    font-weight: normal;padding-left: 15px;
 }
 
 .aweQuery p{
    margin: 0;
    font-size: 12px;
    color: #fff;margin-bottom: 10px;text-align: left;    cursor: pointer;
 }
 #supportPopup{
    /* background: #000; */
    border: 2px solid #303030b5;padding: 0px;border-radius: 8px;
 }
 .issueSaveBtn {
    background-color: #282828;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    font-family: Poppins-Medium;
    font-size: 16px;
    height: 40px;
    outline: none;
    transition: all .4s ease;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-top: 15px;
 }
 
 .issueCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
 }
 .issueCont span{
    font-size: 16px;
    color: #ff5900;
 }
 .authorInfos p{
    margin: 0px;
 }
 .authorInfos p svg{
    color: dimgrey;
    margin-right: 4px;
    font-size: 13px;
 }


 
.block-wrap {
    display: inline-block;
    max-width: 100%;
  }
  .texts {
      display: block;
    overflow: hidden;
      white-space: nowrap;
    text-overflow: ellipsis;
  }
  .icons {
    float: right;
    margin-left: 5px;    color: #de4200;
    font-size: 17px;cursor: pointer;
  }
  #supportPopup span.spanHead{
    background-color: #282828;
    width: 100%;
    display: block;
    padding: 10px;
    text-align: left;
    padding-left: 20px;border-radius: 6px 6px 0 0;
  }
  #supportPopup  .login-wrapper {
    padding: 20px 20px;
}
#supportPopup label{
    text-align: left;
     margin: 0;
    color: #fff;
     min-width: 70px;
}#supportPopup .login-headtext{
    font-size: 18px;
}
.issueSaveBtn:hover{
    opacity: .7;
}
.supportClose{
    cursor: pointer;
    position: relative;
    top: 50px;
    right: 40px;
    color: #fff;
}
.userProfileInfo .supportClose svg{
    color: #fff;
}
#supportPopup textarea::placeholder{
    color: grey;
    font-size: 12px;
}
#supportPopup .login-wrapper{
    padding: 35px 20px;  
}
#supportPopup .modal__close {
    position: absolute;
    top: 19px;
    right: 30px;
}
#supportPopup .modal__content h3{
    margin: 0;
    padding: 0 30px;
    padding-top: 20px;
}
#supportPopup .modal__content .form-control {
    padding: 7px 10px;
    font-size: 14px;
    min-height: 150px;
}