@media (max-width:1920px){
    .image-card img { 
        height: 290px;
    }
    .header-home .search-wrapper,.header-home .search-wrapper.active { 
        right: 28%;
    }
}
@media (max-width:1600px){
    .header-home .search-wrapper,.header-home .search-wrapper.active { 
        right: 24%;
    }
}
@media (max-width:1366px){
    .image-card img {
        height: 180px;
    }
    .reg-wrapper .inputbox {
         margin: 10px auto;
    }
}
@media (max-width:1199px){
    .justify-content-center.lang-wraper-scroll {
        justify-content: left!important;
    }
    .lang-box{
        margin: 2px;
    }
    .image-card img {
        height: 160px;
    }.services-content .service-box .overlay p {
         font-size: 12px;
        line-height: 16px;
    }
    .footer-list {
        flex-direction: column;
    }
    .footer-outer {
        align-items: normal;
     }.footer-right-sectionIcon {
        padding-left: 0;
     }
     .header-home .search-wrapper, .header-home .search-wrapper.active {
         right: 29%;
    }
    .newPost {
         right: 15px;
        top: 0;
        width: 60px;
    }
}
@media (max-width: 1024px){
    .bannerContent h3{
        font-size: 20px;
    }
    .bannerContent h2{
        font-size: 43px;
    }
    .mainHeadText{
        font-size: 30px;
    }
    .image-card img {
        height: 135px;
    }
   .reg-section .bannerImg,.reg-section .bannerImg img  {
    height: 100vh;
    object-fit: cover;
    }
    .booklist-sec {
         height: 430px;
    }
    .reg-wrapper.login-wrapper button {
         width: 35%;
    }
    .login-google-btn img{
        width: 20px !important;
        height: auto !important;
    }
    .reg-section .bannerContent {
        top: 50%;
    }
    .write-here-wrapper {
        padding: 100px 30px 100px;
    }
    .profile-camera{
        right: 42%;
    }
    .user-booklist{
        width: 46%;
    }
    #button { 
        left: 55px;
    }
    .header-home .search-wrapper, .header-home .search-wrapper.active {
        right: 25%;
    }
    .newPost {
        right: 5px;
        top: 0;
        width: 55px;
    }
    .episode-content-sec h3 {
        font-size: 18px;    width: 19rem;
   }
}
@media(max-width:992px) {
    .bannerContent h3 {
        font-size: 18px;
        margin: 22px 0 10px;
    }

    .bannerContent h2 {
        font-size: 32px;
    }

    .slick-arrow.slick-prev {
        width: 20px;
        height: 20px;
    }

    .slick-arrow.slick-next {
        width: 20px;
        height: 20px;
    }

    .categoryTabList ul li {
        width: 33.33%;
    }

    .info-block .texthead {
        font-size: 20px;
    }
    .viewmore-btn{
        font-size: 14px;
        padding: 9px 24px 6px;
    }
    .bannerContent p{
        font-size: 14px;
    }
    .bannerContent{
        top: 45%;
    }
    .languageSelect-mainHead span{
        font-size: 28px;
    }
    /* .languageSection-box{
        overflow: auto;
    } */
    .list-top{
        width: 34%;
    }
    .marquee-item {
        flex: 0 0 16vw;
    }.image-card img {
        height: 140px;
    }
    .section-navarasa .booknavarsa-card .card {
        height: 200px;
    }
    .section-navarasa .booknavarsa-card {
        width: 200px;
    }
    .section-starWriter {
        padding: 60px 0;
    }
    .headdiv-sec {
        font-size: 28px;
    }
    .mainHeadText{
        font-size: 28px;
    }
    .card-content.content .title{
        font-size: 18px;
    }
    .section-title{
        font-size: 28px;
    }
    .section-topbBook {
        padding: 60px 0;
    }
    .application-left {
        margin-left: 30px;
    }
    .footer-list li a{
        font-size: 14px;
    }
    .footer-list li {
        padding: 0 6px;
    }
    .languageSection-box .overlay {
         height: 66vh 
    }
    .section-topbBook ul li img {
         height: 320px;
        object-fit: cover;
    }
   
    .bannerContent {
        top: 52%;
    }
    .bannerContent h3 {
        font-size: 16px;
    }
    .bannerContent h3 {
        font-size: 16px;
    }
    .login-btn{
        font-size: 16px;
    }
    .episode-content-sec h3{
        font-size: 16px;
    }
    .episode-timetext{
        font-size: 14px;
    }
    .align-centertext{
        align-items: baseline;
    }
    .user-booklist{
        width: 95%;
    }
    .userbooklist-section {
        margin: 0 auto;
        text-align: center;
    }
    .user-booklist .tag{
        padding: 3px 18px;
        font-size: 12px;
    }
    .user-book-list-headText{
        margin-bottom: 4px;
        font-size: 22px;
    }
    .detail-subsection{
        display: block;
      }
      .detail-subsection .detailsubtext{
        margin-bottom: 4px;
        font-size: 12px;
      }
      .user-booklist-detail svg {
        font-size: 14px;
        margin-right: 6px;
    }
    .detailsubtext{
        margin-bottom: 5px;
    }
    .user-booklist-status-btn{
        padding: 3px 16px;
    }
    .changepassword-btn{
        padding: 3px 12px;
        font-size: 12px;
    }
    .profile-camera {
        right: 40%;
    }
    .card-columns {
        column-count: 2;
    }
    .create-status {
        width: 100%;
    }
    .Addplus-btn svg {
        font-size: 35px;
    }
    .create-status-text {
        margin-left: 15px;
    }
    .create-status-text h3{
        font-size: 22px;
    }
    .Text{
        display: none;
    }
    .btn-search, .btn-clear{
        font-size: 16px;
    }
    .header-topsearchbtns{
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .searchbar-section{
        align-items: baseline   ;
    }
    .header-topsearchbtns{
        margin-left: 35px;
    }
    .header-home .search-wrapper.active .input-holder{
        width: 330px;
    }
    .header-home .search-wrapper .input-holder{
        height: 42px;
    }
    .header-home .search-wrapper {
         right: 28%;
    }
    .user-booklist{
        padding: 15px;
        min-height: auto;
    }
    #button {
        left: 37px;
    }
    .header-home .search-wrapper.active .input-holder .search-input {
         width: 80%;
         right: 4px;
         top: -1px;
    }
    .header-home .search-wrapper.active .input-holder .search-icon {
         right: 3px;
        bottom: 0;
        top: 3px;
    }
    .header-home .search-wrapper, .header-home .search-wrapper.active {
        right: 25%;
    }
    .progress-container, .progress{
        /* width: 100%; */
        width: 89%;
    }
   
}
@media (max-width:991px){
    .header-home .search-wrapper.active {
        right: 35%;top: 32%;
    } .header-home .search-wrapper{
        top:44%;    right: 35%;
    }
    .episode-content-sec h3 {
        font-size: 18px;
        width: 12rem;
    }

}
@media (max-width:800px){
    .image-card img {
        height: 115px;
    }
    .booklist-sec {
        height: 475px;
    }
    /*.bookdetail-bottom {
         display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        padding-top: 0 !important;
        padding-bottom: 0;
        padding-right: 0;
           width: 100%;
    display: block;    margin-bottom: 20px; */
    .bookdetail-bottom {
        display: flex;
        justify-content: left;
        align-items: baseline;
        text-align: left;
        padding-top: 0 !important;
        padding-bottom: 0;
        padding-right: 0;
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
        justify-content: left;
        text-align: left;
    
    }
    .bookdetail-bottom .v-line{
        display: none;
    }
    .login-wrapper {
        padding: 20px 15px;
    }
    .faTimesRight {
        top: -50px;
        right: -8px;
    }.reg-wrapper.login-wrapper button {
        width: 52%;
    }
    .review-popup-wrapper{
        width: 100%;
    }
    .replySubmtBtn {
        text-align: right;
        width: 100%;
    } 
    .search-autocomplete {
 
        width: 100%;
    }#button {
        left: 15px;
        top: 100px;
    } .header-home .search-wrapper.active .input-holder {
        width: 290px;
    }
    .header-home .search-wrapper.active .input-holder .search-input {
        width: 78%;
        padding: 2px 8px 2px 8px;  
    }
    .detail-decsrptn { 
        min-height: unset;
    }
}
@media(max-width:767px){
    .brTag{
        display: none;
    }
    .section-title,.epiosde-detail-wrapper .main-bookHead {
        font-size: 24px;;
    }
    .lead {
        padding: 20px 15px;
        margin-bottom: 25px;
    }
    .categoryTabList ul li {
        width: 70%;
    }
    .bannerContent h2 {
        font-size: 20px;
    }
    .bannerContent h3 {
        font-size: 14px;
    }
    .bannerContent p {
        font-size: 12px;
    }
    .logoimg{
        width: 130px;
    }
    .header-home{
        top: 0px;
    }
    .bannerContent p {
        padding-bottom: 0;
        max-width: 100%;
        margin: 0 auto 10px;
    }
    .slick-arrow.slick-prev:before, .slick-arrow.slick-next:before{
        opacity: 0;
    }
    .loginFields {
        width: 100%;
    }
    .marquee-item {
        flex: 0 0 16vw;
    }
    .section-navarasa .booknavarsa-card {
        width: 100%;
        margin: 0 auto;
    }
    .section-navarasa .booknavarsa-card .card {
        height: 100%;
    }
    .footer-right-sectionIcon {
        padding-left: 0;
        padding-top: 25px;
    }
    .category-tab .react-tabs__tab{
        padding: 11px 22px;
    }
    .mainHeadText{
        font-size: 24px;
    }
    .languageSelect-mainHead span ,.book-detail-content h3,.episodeList-wrapper h3,.reviewsWrapper .card h1{
        font-size: 24px;
    }
    .categorySection{
        padding: 62.5px 0 100px;
    }
    .lang-border{
        padding: 15px 15px;
    }
    .language-section {
        margin-top: -25px;
    }
    .login-btn {
        font-size: 16px;
        padding: 0 15px;
        margin-top: 0;
        text-decoration: unset;
    }
    .viewmore-btn{
        font-size :12px;
        padding: 5px 10px 6px;
    }
    .info-block{
        padding: 0 15px;
    }
    /* .image-card img,.section-topbBook ul li img, .categoryTabList .img-fluid {
        height: 300px;
    } */
    .booknavarsa-card .content {
         width: 100%;
        max-width: 100%;
         margin-bottom: 15px;
    }
    .list-top, .section-topbBook ul li img {
        width: 100%;
        /* height: 400px; */
        height: auto;
    }
    .category-tab .react-tabs__tab.react-tabs__tab--selected {
      
        margin: 0 6px 10px;
    }
    /* .image-card img,  .categoryTabList .img-fluid {
        height: 255px;
    } */
    .book-detail-content, .bookdetail-bottom{
        padding-left: 15px;
        padding-right: 15px;
        font-size: 14px;
    }
    .book-detail-wrapper{
        padding-top: 85px;
        padding-bottom: 50px;
    }
    .episodeList-wrapper{
        padding: 30px 0 10px;
    }
    .reg-section .login-wrapper {
        padding: 20px 0px;
    }
    .booklist-sec {
        height: auto;
    }
    .episode-content-sec {
         padding: 10px;    margin: 10px 0;
    }
    .bookdetail-bottom .align-items-center {
         margin-bottom: 5px;
    }
    .termsPolicyWrap  .termsHead{
        padding-top: 65px;
    }
    .bookDuration {
       
        width: 100%;
    }
    .unfolow-btn, .follow-btn {
         padding: 3px 10px;
    }
    .follow-btn-div {
        top: -34px;
    }
    .star-writer-detailWrpr {
        padding-top: 100px;
        padding-bottom: 100px;  
    }
    .noDataTxts,.starBooksNodata .noDataTxts {
        min-height: auto;
    }
    .faTimesRight {
        top: -50px;
        right: 8px;
    }
    .popup {
        padding: 15px;
    }
    .underline-login {
        margin: 4px 0 20px;
    }.reg-wrapper.login-wrapper button {
        width: 65%;
    }.reg-section .bannerContent {
        top: 48%;
    }.footer-outer {
         padding: 20px 0;
    }.write-text{
        display: none;
    }.writehere-btn .theme-btn {
         width: 70px;
        height: 70px;
    }
    .writehere-btn:before {
         width: 65%;
        height: 65%;
     }
    .writehere-btn {
         right: 15px;    bottom: 25px;
     }
     .review-popup-wrapper .justify-content-between,.revwsubmt-bottm-sec {
        flex-direction: column;
    }
    .comment-toggle.commntRply {
        width: 100%;margin-bottom: 15px;
    }
    .card .comment{
        padding: 5px;
    }.reply-inputSec {
        margin: 5px 0px 0;
    }
     
    .replySubmtBtn {
        margin-bottom: 15px;
    }
    .epiosde-detail-wrapper .bkBg{
        padding: 15px;
    }
    .write-here-wrapper {
        padding: 80px 15px;
    }
    .starBooksNodata .booklist-sec {
         width: 55%;
        margin: 15px auto;
    }
    .starBooksNodata .booklist-content{
        width: 55%;
        margin: 15px auto;
    }.booklist-content h3 {
        font-size: 20px;
    }
    .write-here-wrapper .rdw-editor-main {
   
        height: 100px;
    }
    .profile-camera {
        right: 29%;
        bottom: -33px;
    }
    .changepassword-btn {
        padding: 3px 10px;
        font-size: 12px;
    }
    .edit-cover{
        font-size: 12px;
        padding: 3px 9px;
        right: 22px;
    }
    .edit-cover svg {
        font-size: 12px;
        margin-right: 5px;
    }
    .user-book-list-headText {
        font-size: 20px;
    }
    .section-star-writerdetails .react-tabs__tab-list{
        width: 100%;
    }
    .card-columns {
        column-count: 1;
    }
    .createStatusPopup.popup{
        width: 95%;
    }
    .follow-btn-div.follow-btn-divProfile {
        display: flex;
        /* flex-direction: column;
        justify-content: end;
        align-items: flex-end; */
    }
    /* .EditProfile {
        margin-right: 0px;
        margin-bottom: 5px;
    } */
    .changepassword-btn{
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .main-bookHead{
        padding-top: 15px;
    }
    .dCenterBk {
        display: flex;
        align-items: center;
        flex-direction: row;
        text-align: left;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
    }
    .select-status-sec {
        margin-left: 0px;
        margin-top: 10px;
    }
    .episodesCountBkDet111{
        margin-bottom: 5px;
    }
    .section-topbBook.section-latestRelease ul .li img {
        height: 300px;
        overflow: hidden;
        border: 0px solid #ffffff3b;
        width: 75% !important;
        object-fit: contain;
    } 
    .section-topbBook.section-latestRelease ul .li {
        width: 80% !important;
        /* border: none; */
        margin: 0 auto;
        display: flex !important;
        flex-direction: column;align-items: center;
    }
    .section-topbBook.section-latestRelease ul .li img {
        object-fit: contain;
        height: 300px;overflow: hidden;
    }
    .bookNameReleased {
       
        padding-top: 15px;
        background: #282828;
        padding-bottom: 15px;
    }
    .services-content .service-box .overlay p {
        font-size: 14px;
        line-height: 20px;
    }
    .order1{
        position: relative;
        order: 1;
    }
    .services-content .img-thumb{ 
        display: flex;    justify-content: center;
    
    }.services-content .service-box .img-thumb img {
         width: 50%;
    }
    .writeHereModal.ChangePaswd .modal__content {
        width: 95%;
    }
    .modalFields .form-group {
    
        flex-direction: column;
        text-align: left;
    }
    .writeHereModal.ChangePaswd .modal__content .modalFields label {
        min-width: 100%;
        text-align: left;
         justify-content: left;
    }
    .add-chapters{
        max-height: 300px;
        overflow-y: auto;
        height: auto;
        overflow-x: hidden;    padding-right: 5px;
    }
    .write-here-wrapper.writeinnerSec {
        padding: 15px 0;
        max-width: 100%;
    }
    .writeHereModal .modal__content h3 {
        margin-top: 0;
    }
    .btn-search, .btn-clear{
        font-size: 14px;
        padding: 3px 15px;
    }
    .header-topsearchbtns{
        margin-left: 0;
    }
    .header-home .search-wrapper.active {
        right: 16%;
        top: 100%;
        bottom: 0;
        left: auto;    transform: none;
    }
    .header-home .search-wrapper {
        right: 42%; 
          /* top: 85%; */
    }
    .search-autocomplete {
         top: 35px;
         left: 0;
    }
    /* .header-home .search-wrapper {
        top: 89%;
        right: 38%;
    }
    .header-home .search-wrapper.active .input-holder {
        max-width: 515px;
        width: 100%;
    }
    .header-home .search-wrapper.active {
        right: 0;
        top: 100%;
        bottom: 0;
        left: auto;
        transform: translate(-26%, 26%);
        width: 100%;
        max-width: 515px;
    }
    .search-autocomplete {
      left: auto;
        top: 39px;
    }
    .header-home .search-wrapper.active .input-holder .search-input {
       
        left: auto;
        right: auto;
    } */
    .header-home .search-wrapper .input-holder {
        height: 36px;
    }
    .header-home .search-wrapper.active .input-holder .search-icon {
 
        margin: 3px 0px
    }.header-home .search-wrapper .input-holder .search-input { 
        top: -4px; 
        font-size: 13px;
    }
    .search-autocomplete ul {
        padding: 0px 0;
    }
    .search-autocomplete ul li {
        font-size: 13px;
        padding: 6px 15px;
    }
    .books-list-wrapper{
        padding-top: 100px;
    }
    .header-home .search-wrapper.active .input-holder .search-icon {
      
        right: -6px;
        bottom: 5px;
    }.login-btn {
        font-size: 14px;
        margin-top: 10px;
    }
    .header-home .search-wrapper .input-holder .search-icon {
    
        height: 30px;
        width: 30px;
    }
    .header-home .search-wrapper .input-holder .search-icon {
  
        padding: 3px 7px;
    }
    .header-home .search-wrapper.active .input-holder .search-input {
         top: -4px;
    }
    .header-home .search-wrapper.active .input-holder .search-icon {
        right: 3px;
        top: 0px;
    }
    .header-home .search-wrapper.active .input-holder .search-icon,
    .header-home .search-wrapper .input-holder .search-icon  {
         width: 30px;
        height: 30px;
    }
    .header-home .search-wrapper.active .input-holder .search-icon{
        padding: 0px 8px;
    }
    .login-btn::after { 
        height: 22px;
    }
    .libraryWrap .bookNameReleased{
        background-color: #fff;
    }
    .AwBanner .slick-dots {
        position: absolute;
        bottom: 50px;
    }
    .rating-wrpr svg {
        width: 20px;
    }  
    .rating-text {
        font-size: 13px;
    }  .lilistPro .position-relative.li{
        margin-bottom: 15px !important;
    }
    .lilistPro .position-relative.li .bookNameReleased{
        background:transparent;
    }
    .episode-content-sec{
        min-height: auto;
    }
    .libRemove.libRemovePro {
        bottom: 100px;
    }
   
    #supportPopup .modal__close {
        position: absolute;
        top: 10px;
        right: 10px;
    }#supportPopup .modal__content h3 {
        margin: 0;
        padding: 0 20px;
        padding-top: 15px;
    }
    #supportPopup  .write-here-wrapper .form-group {
        margin-bottom: 8px;
    }
   
  #supportPopup  .write-here-wrapper.writeinnerSec {
        padding: 8px 0;
    }
}
@media (max-width:640px){
    .marquee-item {
        flex: 0 0 19vw;
    }
    .divOverlap { 
        top: 0; 
        padding-top: 30px;
        margin-top: 30px;padding: 15px
    }
    .header-home .search-wrapper.active {
        right: 10%;
    }
}
@media (max-width:570px){
    .divOverlap { 
        margin-top: 0;
    }
    .header-home .search-wrapper.active {
        right: 15px;
    }
    .newPost {
        right: 10px;
        top: 35px;
    }
}
@media (max-width: 480px){
    .loginBgTxts {
         top: 25%;
         padding-left: 30px;
    }
    .bottomSec{
        padding-left: 30px;  
    }
    .loginBgTxts h2 {
         font-size: 28px;
    }
    .bannerContent h3,.bannerContent p{
        display: none;
    }
    .image-card img, .categoryTabList .img-fluid {
        height: 200px;
    }
    /* .image-card img {
        height: 170px;
    }
    .section-topbBook.section-latestRelease ul li img{
        height: 300px;
    } */
    .image-card img {
        height: 133px;
    }
    .marquee-item {
        flex: 0 0 30vw;
    }
    .popup {
         width: 90%;
    }
    .reg-wrapper .inputbox {
        width: 100%;
    }
    .reg-wrapper .inputbox.col-md-12{
        width: 100% !important;
        margin: 0px !important;
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }
    .login-wrapper .inputbox input,.reg-wrapper select.genderSelect {
         height: 32px;
    }
    .reg-wrapper select.genderSelect {
         padding: 10px 3px 0;
    }
    .login-wrapper .inputbox svg {
         top: 6px;
    }
    .login-wrapper .register { 
        margin: 10px;
    }
.follow-btn { 
    padding: 3px 5px;
}
.follow-btn-div {
     top: -40px;
}
.v-line {
     height: 3px;
}
.bookdetail-bottom {
    
    padding: 15px !important;
 }
 .languageSelect-mainHead span, .book-detail-content h3, .episodeList-wrapper h3 {
    font-size: 20px;
}
.start-writerBookList .react-tabs__tab-list {
  
    width: 100%;
    margin: 10px auto;
}
.login-headtext {
    font-size: 20px;margin-bottom: 0;
}
.faTimesRight {
    right: -3px;
    top: -35px;
}
.login-wrapper {
    padding: 10px 5px;
}.reg-wrapper.login-wrapper button {
    width: 100%;
}.reg-section .bannerImg ,  .reg-section .bannerImg img {
   
    height: 120vh;
}
.list-top, .section-navarasa .booknavarsa-card{
    margin: 0px auto;
    margin-bottom: 10px;
    width: 55%;
}
.changepassword-btn {
    padding: 3px 8px;
    font-size: 11px;
}
.edit-cover {
     bottom: auto;
    top: 0;
}
.header-home .search-wrapper {
    right: 52%;
}
}
@media (max-width:425px){
    .image-card img {
        height: 125px;
    }
    .services-content .service-box .overlay p {
        font-size: 13px;
        line-height: 19px;
    }
    /* .header-home .search-wrapper {
        right: 42%;
    } */
    .detailsubtext {
        font-size: 13px;
    }
    .episode-content-sec h3 { 
        width: 15rem;
    }
    .star-writer-headText h3,.create-status-text h3 {
         font-size: 20px;
    }
    .section-star-writerdetails .react-tabs__tab{
        font-size: 16px;
    }
}
@media (max-width: 375px){
    .loginBgTxts {
        top: 10%;
    }
    .loginBgTxts h2, .loginSection h2 {
        font-size: 23px;
    }
    .bottomSec {
         bottom: 20px;
    }
    .loginBgTxts { 
        width: 95%;
    }
    .termscond-right {
        margin-right: 10px;
    }
    .bottom-secText { 
        font-size: 12px;
    }
    .reg-wrapper.login-wrapper button {
         width: 45%;
    }
    .login-wrapper .register {
        font-size: 14px;
    }
    .star-writer-detailWrpr .profile-picture {
     
        bottom: -120px;
    }
    .login-headtext {
        font-size: 18px;
     }.reg-wrapper.login-wrapper button {
        width: 100%;
    }
    .edit-cover {
        font-size: 11px;
        padding: 3px 8px;
        right: 12px;
    }
    .edit-cover svg {
        font-size: 11px;
    }
    .image-card img {
        height: 110px;
    }.services-content .service-box .overlay p {
        font-size: 12px;
        line-height: 17px;
    }
    /* .header-home .search-wrapper {
        right: 44%;
    } */
    .login-btn {
        font-size: 13px;
        margin-top: 10px;
        padding: 0 8px;
    }.header-home .search-wrapper {
        right: 49%;
    }
}
@media (max-width:320px){
    .marquee-item {
        flex: 0 0 35vw;
    }.services-content .service-box .img-thumb img {
        min-height: 355px;
    }.login-btn {
        font-size: 12px;
    } .header-home .search-wrapper.active .input-holder {
        width: 290px;
    }
}